// images
// import logoLg from "../../assets/images/logo.png";
import logoLg from "../../assets/images/Pulse marketing-01.svg";

import logolink2 from "../../assets/images/links2.png";

// Home
import arrow from "../../assets/images/home/marksmanship.svg";
import bulb from "../../assets/images/svg3.svg";
import arro from "../../assets/images/svg4.svg";

// About
import Ellipse from "../../assets/images/svg2.svg";
import rock3d from "../../assets/images/about/rock/businessman-thinking.svg";
import rock3dd from "../../assets/images/about/rock/rock3dd.png";

import teamEllipse from "../../assets/images/Circles.svg";

import team1 from "../../assets/images/about/team/team1.png";
import team2 from "../../assets/images/about/team/team2.png";
import team3 from "../../assets/images/about/team/team3.png";
import team4 from "../../assets/images/about/team/team4.png";
import team5 from "../../assets/images/about/team/team5.png";
import team6 from "../../assets/images/about/team/team6.png";
import team7 from "../../assets/images/about/team/team7.png";
import team8 from "../../assets/images/about/team/team8.png";
import team9 from "../../assets/images/about/team/team9.png";
import team10 from "../../assets/images/about/team/team10.png";
import team11 from "../../assets/images/about/team/team11.png";
import team12 from "../../assets/images/about/team/team12.png";
import team13 from "../../assets/images/about/team/team13.png";
import team14 from "../../assets/images/about/team/team14.png";
import team15 from "../../assets/images/about/team/team15.png";
import team16 from "../../assets/images/about/team/team16.png";
import team17 from "../../assets/images/about/team/team17.png";
import team18 from "../../assets/images/about/team/team18.png";
import team19 from "../../assets/images/about/team/team19.png";
import team20 from "../../assets/images/about/team/team20.png";
import team21 from "../../assets/images/about/team/team21.png";
import team22 from "../../assets/images/about/team/team22.png";
import team23 from "../../assets/images/about/team/team23.png";
import team24 from "../../assets/images/about/team/team24.png";
import team25 from "../../assets/images/about/team/team25.png";
import team26 from "../../assets/images/about/team/team26.png";
import team27 from "../../assets/images/about/team/team27.png";
import team28 from "../../assets/images/about/team/team28.png";
import team29 from "../../assets/images/about/team/team29.png";
import team30 from "../../assets/images/about/team/team30.png";
import team31 from "../../assets/images/about/team/team31.png";
import team from "../../assets/images/about/team/pending.png";

// Our Services
import scard1 from "../../assets/images/ourservices/s-card1.webp";
import scard1main from "../../assets/images/ourservices/s-card1Main.webp";
import scard2main from "../../assets/images/ourservices/scard2main.webp";
import scard3main from "../../assets/images/ourservices/scard3main.webp";
import scard4main from "../../assets/images/ourservices/scard4main.webp";
import scard5main from "../../assets/images/ourservices/scard5main.webp";
import scard6main from "../../assets/images/ourservices/scard6main.webp";
import leftEllipse from "../../assets/images/play.svg";
import quoteCircle from "../../assets/images/button.svg";

// Portfolio
import bannerback from "../../assets/images/Orm/blur circles.svg";
import storycard1 from "../../assets/images/portfolio/story-card1.webp";
import storycard2 from "../../assets/images/portfolio/story-card2.webp";
import storycard3 from "../../assets/images/portfolio/story-card3.webp";
import storycard4 from "../../assets/images/portfolio/story-card4.webp";
import storycard5 from "../../assets/images/portfolio/story-card5.webp";
import storycard6 from "../../assets/images/portfolio/story-card6.webp";
import storycard7 from "../../assets/images/portfolio/story-card7.webp";
import storycard8 from "../../assets/images/portfolio/story-card8.webp";
import storycard9 from "../../assets/images/portfolio/story-card9.webp";
import storycard10 from "../../assets/images/portfolio/story-card10.webp";
import storycard11 from "../../assets/images/portfolio/story-card11.webp";
import storycard12 from "../../assets/images/portfolio/story-card12.webp";
import storycard13 from "../../assets/images/portfolio/story-card13.webp";
import storycard14 from "../../assets/images/portfolio/story-card14.webp";
import storycard15 from "../../assets/images/portfolio/story-card15.webp";
import storycard16 from "../../assets/images/portfolio/story-card16.webp";
import storycard17 from "../../assets/images/portfolio/story-card17.webp";
import storycard18 from "../../assets/images/portfolio/story-card18.webp";
import teamSpirit from "../../assets/images/portfolio/teamspiritImg.webp";
import backSlider from "../../assets/images/Orm/Bar1.svg";

import storycard013 from "../../assets/images/portfolio/storycard013.png";
import storycard014 from "../../assets/images/portfolio/storycard014.png";
import storycard015 from "../../assets/images/portfolio/storycard015.png";
import storycard016 from "../../assets/images/portfolio/storycard016.png";
import storycard017 from "../../assets/images/portfolio/storycard017.png";
import storycard018 from "../../assets/images/portfolio/storycard018.png";
import storycard019 from "../../assets/images/portfolio/storycard019.png";
import storycard020 from "../../assets/images/portfolio/storycard020.png";
import storycard021 from "../../assets/images/portfolio/storycard021.png";
import storycard022 from "../../assets/images/portfolio/storycard022.png";
import storycard023 from "../../assets/images/portfolio/storycard023.png";
import storycard024 from "../../assets/images/portfolio/storycard024.png";
import storycard025 from "../../assets/images/portfolio/storycard025.png";
import storycard026 from "../../assets/images/portfolio/storycard026.png";
import storycard027 from "../../assets/images/portfolio/storycard027.png";
import storycard028 from "../../assets/images/portfolio/Brpxportfolio/brpx01.jpg";
import storycard029 from "../../assets/images/portfolio/Brpxportfolio/taroko02.jpg";
import storycard030 from "../../assets/images/portfolio/Brpxportfolio/NuVision03.jpg";
import storycard031 from "../../assets/images/portfolio/Brpxportfolio/Evolvere04.jpg";
import storycard032 from "../../assets/images/portfolio/Brpxportfolio/meotech05.jpg";
import storycard033 from "../../assets/images/portfolio/Brpxportfolio/setosaskin06.jpg";
import storycard034 from "../../assets/images/portfolio/Brpxportfolio/greenvalleywellness07.jpg";
import storycard035 from "../../assets/images/portfolio/Brpxportfolio/Skipp08.jpg";
import storycard036 from "../../assets/images/portfolio/Brpxportfolio/richmondstaffing09.jpg";
import storycard037 from "../../assets/images/portfolio/Brpxportfolio/U&TServices010.png";
import storycard038 from "../../assets/images/portfolio/Brpxportfolio/PCRTravel011.jpg";
import storycard039 from "../../assets/images/portfolio/Brpxportfolio/TaxCube012.jpg";
import storycard040 from "../../assets/images/portfolio/Brpxportfolio/Senders013.jpg";
import storycard041 from "../../assets/images/portfolio/Brpxportfolio/Indisability-NDIS14.jpg";
import storycard042 from "../../assets/images/portfolio/Brpxportfolio/32Pearls015.jpg";
import storycard043 from "../../assets/images/portfolio/Brpxportfolio/BurjHomes016.jpg";
import storycard044 from "../../assets/images/portfolio/Brpxportfolio/OmniHR017.jpg";
import storycard045 from "../../assets/images/portfolio/Brpxportfolio/YouthAlive018.jpg";
import storycard046 from "../../assets/images/portfolio/Brpxportfolio/Hanan’sBridal019.jpg";
import storycard047 from "../../assets/images/portfolio/Brpxportfolio/MetamorphicDesigns020.jpg";
import storycard048 from "../../assets/images/portfolio/Brpxportfolio/IdeaConstruction&Contracting021.jpg";
import storycard049 from "../../assets/images/portfolio/Brpxportfolio/GPSCoaching022.jpg";
import storycard050 from "../../assets/images/portfolio/Brpxportfolio/Code11023.jpg";
import storycard051 from "../../assets/images/portfolio/Brpxportfolio/UrulokiCryptoAssets024.jpg";
import storycard052 from "../../assets/images/portfolio/Brpxportfolio/HedgeroeHome025.jpg";
import storycard053 from "../../assets/images/portfolio/Brpxportfolio/BunkerlessGolf026.jpg";
import storycard054 from "../../assets/images/portfolio/Brpxportfolio/AcquisLabs027.jpg";
import storycard057 from "../../assets/images/portfolio/Dispan/6pak.png";
import storycard058 from "../../assets/images/portfolio/Dispan/atgpharma.png";
import storycard059 from "../../assets/images/portfolio/Dispan/cannapiece.png";
import storycard060 from "../../assets/images/portfolio/Dispan/ccg.png";
import storycard061 from "../../assets/images/portfolio/Dispan/flowr.png";
import storycard062 from "../../assets/images/portfolio/Dispan/gvwellness.png";
import storycard063 from "../../assets/images/portfolio/Dispan/ignite.png";

// Hospitalitys
import web1 from "../../assets/images/portfolio/hospitality/1.webp";
import web2 from "../../assets/images/portfolio/hospitality/2.webp";
import web3 from "../../assets/images/portfolio/hospitality/3.webp";
import web4 from "../../assets/images/portfolio/hospitality/4.webp";
import web5 from "../../assets/images/portfolio/hospitality/5.webp";
import web6 from "../../assets/images/portfolio/hospitality/6.webp";
import web7 from "../../assets/images/portfolio/hospitality/story001.png";
import web8 from "../../assets/images/portfolio/hospitality/story002.png";
import web9 from "../../assets/images/portfolio/hospitality/story003.png";
import web10 from "../../assets/images/portfolio/hospitality/story004.png";
import web11 from "../../assets/images/portfolio/hospitality/story005.png";
import web12 from "../../assets/images/portfolio/hospitality/story006.png";
import web13 from "../../assets/images/portfolio/hospitality/story007.png";
import web14 from "../../assets/images/portfolio/hospitality/story008.png";
import web15 from "../../assets/images/portfolio/hospitality/story009.png";
import web16 from "../../assets/images/portfolio/hospitality/story0010.png";
import web17 from "../../assets/images/portfolio/hospitality/story0011.png";
import web18 from "../../assets/images/portfolio/hospitality/story0012.png";

// Construction
import cons1 from "../../assets/images/portfolio/construction/1.webp";
import cons2 from "../../assets/images/portfolio/construction/2.webp";
import cons3 from "../../assets/images/portfolio/construction/3.webp";
import cons4 from "../../assets/images/portfolio/construction/4.webp";
import cons5 from "../../assets/images/portfolio/construction/cons5.png";
import cons6 from "../../assets/images/portfolio/construction/cons6.png";
import cons7 from "../../assets/images/portfolio/construction/cons7.png";
import cons8 from "../../assets/images/portfolio/construction/cons8.png";
import cons9 from "../../assets/images/portfolio/construction/cons9.png";
import cons10 from "../../assets/images/portfolio/construction/cons10.png";
import cons11 from "../../assets/images/portfolio/construction/cons11.png";
import cons12 from "../../assets/images/portfolio/construction/cons12.png";
import cons13 from "../../assets/images/portfolio/construction/cons13.png";
import cons14 from "../../assets/images/portfolio/construction/cons14.png";
import cons15 from "../../assets/images/portfolio/construction/cons15.png";
import cons16 from "../../assets/images/portfolio/construction/cons16.png";

// Logo design
// import logocard1 from "../../assets/images/Logo-design/1.webp";
// import logocard2 from "../../assets/images/Logo-design/2.webp";
// import logocard3 from "../../assets/images/Logo-design/3.webp";
// import logocard4 from "../../assets/images/Logo-design/4.webp";
// import logocard5 from "../../assets/images/Logo-design/5.webp";
// import logocard6 from "../../assets/images/Logo-design/6.webp";
// import logocard7 from "../../assets/images/Logo-design/7.webp";
// import logocard8 from "../../assets/images/Logo-design/8.webp";
// import logocard9 from "../../assets/images/Logo-design/9.webp";
// import logocard10 from "../../assets/images/Logo-design/10.webp";
import logocard1 from "../../assets/images/Logo-design/0022.png";
import logocard2 from "../../assets/images/Logo-design/0023.png";
import logocard3 from "../../assets/images/Logo-design/0024.png";
import logocard4 from "../../assets/images/Logo-design/0025.png";
import logocard5 from "../../assets/images/Logo-design/0026.png";
import logocard6 from "../../assets/images/Logo-design/0017.png";
import logocard7 from "../../assets/images/Logo-design/0018.png";
import logocard8 from "../../assets/images/Logo-design/0019.png";
import logocard9 from "../../assets/images/Logo-design/0020.png";
import logocard10 from "../../assets/images/Logo-design/0021.png";
import logocard11 from "../../assets/images/Logo-design/11.webp";
import logocard12 from "../../assets/images/Logo-design/12.webp";
import logocard13 from "../../assets/images/Logo-design/13.webp";
import logocard14 from "../../assets/images/Logo-design/14.webp";
import logocard15 from "../../assets/images/Logo-design/15.webp";
import logocard16 from "../../assets/images/Logo-design/16.webp";
import logocard17 from "../../assets/images/Logo-design/17.webp";
import logocard18 from "../../assets/images/Logo-design/18.webp";
import logocard19 from "../../assets/images/Logo-design/19.png";
import logocard20 from "../../assets/images/Logo-design/20.png";
import logocard21 from "../../assets/images/Logo-design/21.png";
import logocard22 from "../../assets/images/Logo-design/22.png";
import logocard23 from "../../assets/images/Logo-design/23.png";
import logocard24 from "../../assets/images/Logo-design/24.png";
import logocard25 from "../../assets/images/Logo-design/25.png";
import logocard26 from "../../assets/images/Logo-design/26.png";
import logocard27 from "../../assets/images/Logo-design/27.png";
import logocard28 from "../../assets/images/Logo-design/28.png";
import logocard29 from "../../assets/images/Logo-design/0011.png";
import logocard30 from "../../assets/images/Logo-design/0080.png";
import logocard31 from "../../assets/images/Logo-design/0081.png";
import logocard32 from "../../assets/images/Logo-design/0082.png";
import logocard33 from "../../assets/images/Logo-design/0083.png";
import logocard34 from "../../assets/images/Logo-design/0090.png";
import logocard35 from "../../assets/images/Logo-design/0091.png";
import logocard36 from "../../assets/images/Logo-design/0092.png";
import logocard37 from "../../assets/images/Logo-design/0093.png";
import logocard38 from "../../assets/images/Logo-design/0094.png";
import logocard39 from "../../assets/images/Logo-design/0095.png";
import logocard40 from "../../assets/images/Logo-design/0096.png";
import logocard41 from "../../assets/images/Logo-design/0097.png";
import logocard42 from "../../assets/images/Logo-design/0001.png";
import logocard43 from "../../assets/images/Logo-design/0002.png";
import logocard44 from "../../assets/images/Logo-design/00010.png";
import logocard45 from "../../assets/images/Logo-design/00011.png";
import logocard46 from "../../assets/images/Logo-design/00012.png";
import logocard47 from "../../assets/images/Logo-design/00013.png";
import logocard48 from "../../assets/images/Logo-design/00014.png";
import logocard49 from "../../assets/images/Logo-design/more-01.png";
import logocard50 from "../../assets/images/Logo-design/more-02.png";
import logocard51 from "../../assets/images/Logo-design/more-03.png";
import logocard52 from "../../assets/images/Logo-design/more-04.png";
import logocard53 from "../../assets/images/Logo-design/more-05.png";
import logocard54 from "../../assets/images/Logo-design/more-06.png";
import logocard55 from "../../assets/images/Logo-design/more-07.png";
import logocard56 from "../../assets/images/Logo-design/more-08.png";
import logocard57 from "../../assets/images/Logo-design/more-09.png";
import logocard58 from "../../assets/images/Logo-design/more-10.png";
import logocard59 from "../../assets/images/CardsLogo/1.jpg";
import logocard60 from "../../assets/images/CardsLogo/2.jpg";
import logocard61 from "../../assets/images/CardsLogo/3.jpg";
import logocard62 from "../../assets/images/CardsLogo/4.jpg";
import logocard63 from "../../assets/images/CardsLogo/5.jpg";
import logocard64 from "../../assets/images/CardsLogo/6.jpg";
import logocard65 from "../../assets/images/CardsLogo/7.jpg";
import logocard66 from "../../assets/images/CardsLogo/8.jpg";
import logocard67 from "../../assets/images/CardsLogo/9.jpg";
import logocard68 from "../../assets/images/CardsLogo/10.jpg";
import logocard69 from "../../assets/images/CardsLogo/11.jpg";
import logocard70 from "../../assets/images/CardsLogo/12.jpg";
import logocard71 from "../../assets/images/CardsLogo/13.jpg";
import logocard72 from "../../assets/images/CardsLogo/14.jpg";
import logocard73 from "../../assets/images/CardsLogo/15.jpg";
import logocard74 from "../../assets/images/CardsLogo/16.jpg";
import logocard75 from "../../assets/images/CardsLogo/17.jpg";
import logocard76 from "../../assets/images/CardsLogo/18.jpg";
import logocard77 from "../../assets/images/CardsLogo/19.jpg";
import logocard78 from "../../assets/images/CardsLogo/20.jpg";
import logocard79 from "../../assets/images/CardsLogo/21.jpg";
import logocard80 from "../../assets/images/CardsLogo/22.jpg";
import logocard81 from "../../assets/images/CardsLogo/23.jpg";
import logocard82 from "../../assets/images/CardsLogo/24.jpg";
import logocard83 from "../../assets/images/CardsLogo/25.jpg";
import logocard84 from "../../assets/images/CardsLogo/26.jpg";
import logocard85 from "../../assets/images/CardsLogo/27.jpg";
import logocard86 from "../../assets/images/CardsLogo/28.jpg";
import logocard87 from "../../assets/images/CardsLogo/29.jpg";
import logocard88 from "../../assets/images/CardsLogo/30.jpg";
import logocard89 from "../../assets/images/CardsLogo/31.png";
import logocard90 from "../../assets/images/CardsLogo/32.jpg";
import logocard91 from "../../assets/images/CardsLogo/33.jpg";
import logocard92 from "../../assets/images/CardsLogo/34.jpg";
import logocard93 from "../../assets/images/CardsLogo/35.jpg";
import logocard94 from "../../assets/images/CardsLogo/36.jpg";
import logocard95 from "../../assets/images/CardsLogo/37.jpg";
import logocard96 from "../../assets/images/CardsLogo/38.jpg";
import logocard97 from "../../assets/images/CardsLogo/39.jpg";
import logocard98 from "../../assets/images/CardsLogo/40.jpg";

// Applications
import gym from "../../assets/images/App-development/gym.webp";
import img2 from "../../assets/images/App-development/img2.webp";
import img3 from "../../assets/images/App-development/img3.webp";
import img4 from "../../assets/images/App-development/img4.webp";
import img5 from "../../assets/images/App-development/img5.webp";
import img6 from "../../assets/images/App-development/img6.webp";
import img7 from "../../assets/images/App-development/img7.webp";
import img8 from "../../assets/images/App-development/img8.webp";
import img9 from "../../assets/images/App-development/img9.webp";
import img10 from "../../assets/images/App-development/img10.webp";
import img11 from "../../assets/images/App-development/img11.webp";
import img12 from "../../assets/images/App-development/img12.webp";
import img13 from "../../assets/images/App-development/app001.png";
import img15 from "../../assets/images/App-development/922shots_so.png";
import img16 from "../../assets/images/App-development/386shots_so.png";
import img17 from "../../assets/images/App-development/441shots_so.png";
import img18 from "../../assets/images/App-development/679shots_so.png";
import img19 from "../../assets/images/App-development/3shots_so.png";
import img20 from "../../assets/images/App-development/855shots_so.png";
import img21 from "../../assets/images/App-development/70shots_so.png";
import img22 from "../../assets/images/App-development/372shots_so.png";

// Ecommerce files
import eco1 from "../../assets/images/portfolio/ecommerce/1.webp";
import eco2 from "../../assets/images/portfolio/ecommerce/2.webp";
import eco3 from "../../assets/images/portfolio/ecommerce/3.webp";
import eco4 from "../../assets/images/portfolio/ecommerce/4.webp";
import eco5 from "../../assets/images/portfolio/ecommerce/5.webp";
import eco6 from "../../assets/images/portfolio/ecommerce/6.webp";
import eco7 from "../../assets/images/portfolio/ecommerce/7.webp";
import eco8 from "../../assets/images/portfolio/ecommerce/8.webp";
import eco9 from "../../assets/images/portfolio/ecommerce/9.webp";
import eco10 from "../../assets/images/portfolio/ecommerce/10.webp";
import eco11 from "../../assets/images/portfolio/ecommerce/11.webp";
import eco12 from "../../assets/images/portfolio/ecommerce/12.webp";
import eco13 from "../../assets/images/portfolio/ecommerce/13.webp";
import eco14 from "../../assets/images/portfolio/ecommerce/14.webp";
import eco15 from "../../assets/images/portfolio/ecommerce/15.webp";
import eco16 from "../../assets/images/portfolio/ecommerce/16.webp";
import eco17 from "../../assets/images/portfolio/ecommerce/17.webp";
import eco18 from "../../assets/images/portfolio/ecommerce/18.webp";
import eco19 from "../../assets/images/portfolio/ecommerce/19.webp";
import eco20 from "../../assets/images/portfolio/ecommerce/20.webp";
import eco21 from "../../assets/images/portfolio/ecommerce/21.webp";
import eco22 from "../../assets/images/portfolio/ecommerce/22.webp";
import eco23 from "../../assets/images/portfolio/ecommerce/23.webp";
import eco24 from "../../assets/images/portfolio/ecommerce/24.webp";
import eco25 from "../../assets/images/portfolio/ecommerce/25.webp";
import eco26 from "../../assets/images/portfolio/ecommerce/ecom01.png";
import eco27 from "../../assets/images/portfolio/ecommerce/ecom02.png";
import eco28 from "../../assets/images/portfolio/ecommerce/httpsau.seafolly.com.jpg";
import eco29 from "../../assets/images/portfolio/ecommerce/httpsbarkingironsroastery.com.jpg";
import eco30 from "../../assets/images/portfolio/ecommerce/100200.png";
import eco31 from "../../assets/images/portfolio/ecommerce/httpscoffeebeansdelivered.com.au.png";
import eco32 from "../../assets/images/portfolio/ecommerce/httpsen.volleyballworld.com.jpg";
import eco33 from "../../assets/images/portfolio/ecommerce/httpsfactorbikes.com.jpg";
import eco34 from "../../assets/images/portfolio/ecommerce/httpsjackrudycocktailco.com.jpg";
import eco35 from "../../assets/images/portfolio/ecommerce/httpsjadeswim.com.jpg";
import eco36 from "../../assets/images/portfolio/ecommerce/httpsjococups.com.jpg";
import eco37 from "../../assets/images/portfolio/ecommerce/httpsmindyweiss.com.jpg";
import eco38 from "../../assets/images/portfolio/ecommerce/httpsosanabar.com.jpg";
import eco39 from "../../assets/images/portfolio/ecommerce/httpsrvlifestyle.com.jpg";
import eco40 from "../../assets/images/portfolio/ecommerce/httpssaaqitechdeveloper.comasma-ayesha-01.jpg";
import eco41 from "../../assets/images/portfolio/ecommerce/httpssaaqitechdeveloper.comhazed.jpg";
import eco42 from "../../assets/images/portfolio/ecommerce/httpssaaqitechdeveloper.commb.jpg";
import eco43 from "../../assets/images/portfolio/ecommerce/httpssaaqitechdeveloper.comthe_indian_pantry.jpg";
import eco44 from "../../assets/images/portfolio/ecommerce/httpssignaturemazda.uatlink.com.jpg";
import eco45 from "../../assets/images/portfolio/ecommerce/httpssodashi.com.au.jpg";
import eco46 from "../../assets/images/portfolio/ecommerce/httpswearelittlegiants.com.jpg";
import eco47 from "../../assets/images/portfolio/ecommerce/httpswww.baseballmonkey.com.jpg";
import eco48 from "../../assets/images/portfolio/ecommerce/httpswww.cars.com.jpg";
import eco49 from "../../assets/images/portfolio/ecommerce/httpswww.drivencoffee.com.jpg";
import eco50 from "../../assets/images/portfolio/ecommerce/httpswww.henryjsocks.co.uk.jpg";
import eco51 from "../../assets/images/portfolio/ecommerce/httpswww.kiwivelo.co.nz.jpg";
import eco52 from "../../assets/images/portfolio/ecommerce/httpswww.mcas.com.au.jpg";
import eco53 from "../../assets/images/portfolio/ecommerce/httpswww.newhollandcoffee.com.jpg";
import eco54 from "../../assets/images/portfolio/ecommerce/httpswww.resplendentceylon.com.jpg";
import eco55 from "../../assets/images/portfolio/ecommerce/httpswww.saniswiss.com.jpg";
import eco56 from "../../assets/images/portfolio/ecommerce/httpswww.shoprootscience.com.jpg";
import eco57 from "../../assets/images/portfolio/ecommerce/httpswww.soccer.com.jpg";
import eco58 from "../../assets/images/portfolio/ecommerce/httpswww.societyoflifestyle.com.jpg";
import eco59 from "../../assets/images/portfolio/ecommerce/httpswww.striiiipes.com.jpg";
import eco60 from "../../assets/images/portfolio/ecommerce/httpswww.tennis-point.co.uk.jpg";
import eco61 from "../../assets/images/portfolio/ecommerce/httpswww.yubico.com.jpg";
import eco62 from "../../assets/images/portfolio/ecommerce/httpswww.zefal.comen.jpg";
import eco63 from "../../assets/images/portfolio/ecommerce/httpswww.zopdealer.com.jpg";
// Blogs
import blog1 from "../../assets/images/blogs/1.png";
import blog2 from "../../assets/images/blogs/2.png";
import blog3 from "../../assets/images/blogs/3.png";
import blog4 from "../../assets/images/blogs/4.png";
import blog5 from "../../assets/images/blogs/5.png";
import blog6 from "../../assets/images/blogs/6.png";
import blog7 from "../../assets/images/blogs/7.png";
import blog8 from "../../assets/images/blogs/8.png";
import blog9 from "../../assets/images/blogs/9.png";
import blog10 from "../../assets/images/blogs/10.png";
import blog11 from "../../assets/images/blogs/11.png";
import blog12 from "../../assets/images/blogs/12.png";
import blog13 from "../../assets/images/blogs/13.png";
import blog14 from "../../assets/images/blogs/14.png";
import blog15 from "../../assets/images/blogs/15.png";
import blog16 from "../../assets/images/blogs/16.png";
import blog17 from "../../assets/images/blogs/17.png";
import blog18 from "../../assets/images/blogs/18.png";
import blog19 from "../../assets/images/blogs/19.png";
import blog20 from "../../assets/images/blogs/20.png";
import blog21 from "../../assets/images/blogs/21.png";
import blog22 from "../../assets/images/blogs/22.png";
import blog23 from "../../assets/images/blogs/23.png";
import blog24 from "../../assets/images/blogs/24.png";
import blog25 from "../../assets/images/blogs/25.png";
import blog26 from "../../assets/images/blogs/26.png";
import blog27 from "../../assets/images/blogs/27.png";
import blog28 from "../../assets/images/blogs/28.png";
import blog29 from "../../assets/images/blogs/29.png";
import blog30 from "../../assets/images/blogs/30.png";
import blog31 from "../../assets/images/blogs/31.png";
import blog32 from "../../assets/images/blogs/32.png";
import blog33 from "../../assets/images/blogs/33.png";
import blog34 from "../../assets/images/blogs/34.png";
import blog35 from "../../assets/images/blogs/35.png";
import blog36 from "../../assets/images/blogs/36.png";
import blog37 from "../../assets/images/blogs/37.png";
import blog38 from "../../assets/images/blogs/38.png";
import blog39 from "../../assets/images/blogs/39.png";
import blogA from "../../assets/images/blogs/A.png";
import blogB from "../../assets/images/blogs/B.png";

// restaurant url here
import rest01 from "../../assets/images/portfolio/restaurant/httpsbelushis.com.jpg";
import rest02 from "../../assets/images/portfolio/restaurant/httpsfazolis.com.jpg";
import rest03 from "../../assets/images/portfolio/restaurant/httpssaaqitechdeveloper.comdonpeppe13n.jpg";
import rest04 from "../../assets/images/portfolio/restaurant/httpssaaqitechdeveloper.comfoogra-1014.jpg";
import rest05 from "../../assets/images/portfolio/restaurant/httpssaaqitechdeveloper.comgrillino14.jpg";
import rest06 from "../../assets/images/portfolio/restaurant/httpssaaqitechdeveloper.comlaurent30n.jpg";
import rest07 from "../../assets/images/portfolio/restaurant/httpssaaqitechdeveloper.comthe_indian_pantry.jpg";
import rest08 from "../../assets/images/portfolio/restaurant/httpswww.alfanarrestaurant.com.jpg";
import rest09 from "../../assets/images/portfolio/restaurant/httpswww.derwandi.ae.jpg";
// restaurant url services
import ser01 from "../../assets/images/portfolio/services/httpsabsolutedental.com.au.jpg";
import ser02 from "../../assets/images/portfolio/services/httpsaimsparkk.com.jpg";
import ser03 from "../../assets/images/portfolio/services/httpsaphroditehairstudio.ca.jpg";
import ser04 from "../../assets/images/portfolio/services/httpsarticketbcn.org.jpg";
import ser05 from "../../assets/images/portfolio/services/httpsavantilandscaping.ca.jpg";
import ser06 from "../../assets/images/portfolio/services/httpsbettertax.org.jpg";
import ser07 from "../../assets/images/portfolio/services/httpsbrilliantelectric.ca.jpg";
import ser08 from "../../assets/images/portfolio/services/httpscactuslandscaping.ca.jpg";
import ser09 from "../../assets/images/portfolio/services/httpscandyldn.com.jpg";
import ser10 from "../../assets/images/portfolio/services/httpsdulexpropainting.ca.jpg";
import ser11 from "../../assets/images/portfolio/services/httpsexperiencenve.com.jpg";
import ser12 from "../../assets/images/portfolio/services/httpsfuzelogistics.comen.jpg";
import ser13 from "../../assets/images/portfolio/services/httpsglassfixcanada.com.jpg";
import ser14 from "../../assets/images/portfolio/services/httpskelownadetails.com.jpg";
import ser15 from "../../assets/images/portfolio/services/httpsmichellemarin.comwork.jpg";
import ser16 from "../../assets/images/portfolio/services/httpsmillbridgedentistry.com.jpg";
import ser17 from "../../assets/images/portfolio/services/httpsnhtiusa.com.jpg";
import ser18 from "../../assets/images/portfolio/services/httpspandapatches.com.jpg";
import ser19 from "../../assets/images/portfolio/services/httpsplatinumlotus.ca.jpg";
import ser20 from "../../assets/images/portfolio/services/httpspulsemarketing.io.jpg";
import ser21 from "../../assets/images/portfolio/services/httpssaaqitechdeveloper.comaim_spark.jpg";
import ser22 from "../../assets/images/portfolio/services/httpssaaqitechdeveloper.comcraftsman.jpg";
import ser23 from "../../assets/images/portfolio/services/httpssaaqitechdeveloper.comdigitalagency2.jpg";
import ser24 from "../../assets/images/portfolio/services/httpssaaqitechdeveloper.comecho-degital-agency.jpg";
import ser25 from "../../assets/images/portfolio/services/httpssaaqitechdeveloper.comphotostudio.jpg";
import ser26 from "../../assets/images/portfolio/services/httpssaaqitechdeveloper.comphoto_5.jpg";
import ser27 from "../../assets/images/portfolio/services/httpsstarline.solutions.jpg";
import ser28 from "../../assets/images/portfolio/services/httpsvibrascreener.com.jpg";
import ser29 from "../../assets/images/portfolio/services/httpsworkinghandsak.com.jpg";
import ser30 from "../../assets/images/portfolio/services/httpswww.alliedlondon.com.jpg";
import ser31 from "../../assets/images/portfolio/services/httpswww.armadillo.one.jpg";
import ser32 from "../../assets/images/portfolio/services/httpswww.bridge.partners.jpg";
import ser33 from "../../assets/images/portfolio/services/httpswww.endodontistatlanta.com.jpg";
import ser34 from "../../assets/images/portfolio/services/httpswww.villagedentaldtc.com.jpg";
import ser35 from "../../assets/images/portfolio/services/httpswww.windermere.com.jpg";
import ser36 from "../../assets/images/portfolio/services/httpswww.zenoasis-spa.com.jpg";
// restaurant url club
import club01 from "../../assets/images/portfolio/club/httpsaofmakeup.com.jpg";
import club02 from "../../assets/images/portfolio/club/httpsbelushis.com.jpg";
import club03 from "../../assets/images/portfolio/club/httpseliteceu.com.jpg";
import club04 from "../../assets/images/portfolio/club/httpssaaqitechdeveloper.comemojination106 .jpg";
import club05 from "../../assets/images/portfolio/club/httpssaaqitechdeveloper.comemojination106.jpg";
import club06 from "../../assets/images/portfolio/club/httpssaaqitechdeveloper.comhellix1020.jpg";
import club07 from "../../assets/images/portfolio/club/httpssaaqitechdeveloper.comhuka102.jpg";
import club08 from "../../assets/images/portfolio/club/httpswww.raw.co.uk.jpg";
import club09 from "../../assets/images/portfolio/club/httpswww.revolution-bars.co.uk.png";
// restaurant url landingpage
import lan01 from "../../assets/images/portfolio/landing-page/httpscollectivegen.com.png";
import lan02 from "../../assets/images/portfolio/landing-page/httpscorporette.com.png";
import lan03 from "../../assets/images/portfolio/landing-page/httpskayandtee.ca.jpg";
import lan04 from "../../assets/images/portfolio/landing-page/httpssaaqitechdeveloper.combrennannevadainc-final (2).jpg";
import lan05 from "../../assets/images/portfolio/landing-page/httpssaaqitechdeveloper.combrennannevadainc-final.jpg";
// import lan06 from "../../assets/images/portfolio/landing-page/httpssaaqitechdeveloper.comcowboyjerky.jpg";
import lan07 from "../../assets/images/portfolio/landing-page/httpssaaqitechdeveloper.comlp-finale.jpg";
import lan08 from "../../assets/images/portfolio/landing-page/httpstheposhpicnicatx.com.jpg";
import lan09 from "../../assets/images/portfolio/landing-page/httpsvintagerevivals.com.png";
import lan10 from "../../assets/images/portfolio/landing-page/httpswww.epidemicsound.com.png";
import lan11 from "../../assets/images/portfolio/landing-page/httpswww.hoopladigital.com.png";
import lan12 from "../../assets/images/portfolio/landing-page/httpswww.productionbase.co.uk.jpg";
import lan13 from "../../assets/images/portfolio/landing-page/httpswww.redproductioncompany.com.png";
import lan14 from "../../assets/images/portfolio/landing-page/httpswww.teachjunkie.com.png";
import lan15 from "../../assets/images/portfolio/landing-page/httpswww.thephoblographer.com.png";
import lan16 from "../../assets/images/portfolio/landing-page/httpswww.tunefind.com.png";
import lan17 from "../../assets/images/portfolio/landing-page/httpszynthentertainment.com.jpg";
import lan18 from "../../assets/images/portfolio/landing-page/httpswww.plex.tv.png";
// restaurant url realestate
import real01 from "../../assets/images/portfolio/realestate/httpsbscottrealtydmv.com.jpg";
import real02 from "../../assets/images/portfolio/realestate/httpsfamproperties.com.jpg";
import real03 from "../../assets/images/portfolio/realestate/httpsidealagent.com.jpg";
import real04 from "../../assets/images/portfolio/realestate/httpswww.knightfrank.ae.jpg";
import real05 from "../../assets/images/portfolio/realestate/httpssaaqitechdeveloper.comarcatec-101.jpg";
import real06 from "../../assets/images/portfolio/realestate/httpssaaqitechdeveloper.comhellix1020.jpg";
import real07 from "../../assets/images/portfolio/realestate/httpswww.bhomes.comen.jpg";
import real08 from "../../assets/images/portfolio/realestate/httpswww.hiltonhyland.com.jpg";
import real09 from "../../assets/images/portfolio/realestate/httpswww.knightfrank.ae.jpg";

// export files
export const services = {
  ser01: ser01,
  ser02: ser02,
  ser03: ser03,
  ser04: ser04,
  ser05: ser05,
  ser06: ser06,
  ser07: ser07,
  ser08: ser08,
  ser09: ser09,
  ser10: ser10,
  ser11: ser11,
  ser12: ser12,
  ser13: ser13,
  ser14: ser14,
  ser15: ser15,
  ser16: ser16,
  ser17: ser17,
  ser18: ser18,
  ser19: ser19,
  ser20: ser20,
  ser21: ser21,
  ser22: ser22,
  ser23: ser23,
  ser24: ser24,
  ser25: ser25,
  ser26: ser26,
  ser27: ser27,
  ser28: ser28,
  ser29: ser29,
  ser30: ser30,
  ser31: ser31,
  ser32: ser32,
  ser33: ser33,
  ser34: ser34,
  ser35: ser35,
  ser36: ser36,
};

export const restaurant = {
  rest01: rest01,
  rest02: rest02,
  rest03: rest03,
  rest04: rest04,
  rest05: rest05,
  rest06: rest06,
  rest07: rest07,
  rest08: rest08,
  rest09: rest09,
};
export const club = {
  club01: club01,
  club02: club02,
  club03: club03,
  club04: club04,
  club05: club05,
  club06: club06,
  club07: club07,
  club08: club08,
  club09: club09,
};
export const landingpage = {
  lan01: lan01,
  lan02: lan02,
  lan03: lan03,
  lan04: lan04,
  lan05: lan05,
  // lan06: lan06,
  lan07: lan07,
  lan08: lan08,
  lan09: lan09,
  lan10: lan10,
  lan11: lan11,
  lan12: lan12,
  lan13: lan13,
  lan14: lan14,
  lan15: lan15,
  lan16: lan16,
  lan17: lan17,
  lan18: lan18,
};
export const realestate = {
  real01: real01,
  real02: real02,
  real03: real03,
  real04: real04,
  real05: real05,
  real06: real06,
  real07: real07,
  real08: real08,
  real09: real09,
};
export const ecommerce = {
  eco1: eco1,
  eco2: eco2,
  eco3: eco3,
  eco4: eco4,
  eco5: eco5,
  eco6: eco6,
  eco7: eco7,
  eco8: eco8,
  eco9: eco9,
  eco10: eco10,
  eco11: eco11,
  eco12: eco12,
  eco13: eco13,
  eco14: eco14,
  eco15: eco15,
  eco16: eco16,
  eco17: eco17,
  eco18: eco18,
  eco19: eco19,
  eco20: eco20,
  eco21: eco21,
  eco22: eco22,
  eco23: eco23,
  eco24: eco24,
  eco25: eco25,
  eco26: eco26,
  eco27: eco27,
  eco28: eco28,
  eco29: eco29,
  eco30: eco30,
  eco31: eco31,
  eco32: eco32,
  eco33: eco33,
  eco34: eco34,
  eco35: eco35,
  eco36: eco36,
  eco37: eco37,
  eco38: eco38,
  eco39: eco39,
  eco40: eco40,
  eco41: eco41,
  eco42: eco42,
  eco43: eco43,
  eco44: eco44,
  eco45: eco45,
  eco46: eco46,
  eco47: eco47,
  eco48: eco48,
  eco49: eco49,
  eco50: eco50,
  eco51: eco51,
  eco52: eco52,
  eco53: eco53,
  eco54: eco54,
  eco55: eco55,
  eco56: eco56,
  eco57: eco57,
  eco58: eco58,
  eco59: eco59,
  eco60: eco60,
  eco61: eco61,
  eco62: eco62,
  eco63: eco63,
};

export { logoLg };
export { logolink2 };

export const homeImg = {
  arrow: arrow,
  bulb: bulb,
  arro: arro,
};

export const aboutImg = {
  Ellipse: Ellipse,
  rock3d: rock3d,
  rock3dd: rock3dd,
  teamEllipse: teamEllipse,
  team1: team1,
  team2: team2,
  team3: team3,
  team4: team4,
  team5: team5,
  team6: team6,
  team7: team7,
  team8: team8,
  team9: team9,
  team10: team10,
  team11: team11,
  team12: team12,
  team13: team13,
  team14: team14,
  team15: team15,
  team16: team16,
  team17: team17,
  team18: team18,
  team19: team19,
  team20: team20,
  team21: team21,
  team22: team22,
  team23: team23,
  team24: team24,
  team25: team25,
  team26: team26,
  team27: team27,
  team28: team28,
  team29: team29,
  team30: team30,
  team31: team31,
};

export const serviceImg = {
  scard1: scard1,
  quoteCircle: quoteCircle,
  leftEllipse: leftEllipse,
  scard1main: scard1main,
  scard2main: scard2main,
  scard3main: scard3main,
  scard4main: scard4main,
  scard5main: scard5main,
  scard6main: scard6main,
};

export const portfolio = {
  bannerback: bannerback,
  storycard1: storycard1,
  storycard2: storycard2,
  storycard3: storycard3,
  storycard4: storycard4,
  storycard5: storycard5,
  storycard6: storycard6,
  storycard7: storycard7,
  storycard8: storycard8,
  storycard9: storycard9,
  storycard10: storycard10,
  storycard11: storycard11,
  storycard12: storycard12,
  storycard13: storycard13,
  storycard013: storycard013,
  storycard014: storycard014,
  storycard015: storycard015,
  storycard016: storycard016,
  storycard017: storycard017,
  storycard018: storycard018,
  storycard019: storycard019,
  storycard020: storycard020,
  storycard021: storycard021,
  storycard022: storycard022,
  storycard023: storycard023,
  storycard024: storycard024,
  storycard025: storycard025,
  storycard026: storycard026,
  storycard027: storycard027,

  storycard028: storycard028,
  storycard029: storycard029,
  storycard030: storycard030,
  storycard031: storycard031,
  storycard032: storycard032,
  storycard033: storycard033,
  storycard034: storycard034,
  storycard035: storycard035,
  storycard036: storycard036,
  storycard037: storycard037,
  storycard038: storycard038,
  storycard039: storycard039,
  storycard040: storycard040,
  storycard041: storycard041,
  storycard042: storycard042,
  storycard043: storycard043,
  storycard044: storycard044,
  storycard045: storycard045,
  storycard046: storycard046,
  storycard047: storycard047,
  storycard048: storycard048,
  storycard049: storycard049,
  storycard050: storycard050,
  storycard051: storycard051,
  storycard052: storycard052,
  storycard053: storycard053,
  storycard054: storycard054,
  storycard057: storycard057,
  storycard058: storycard058,
  storycard059: storycard059,
  storycard060: storycard060,
  storycard061: storycard061,
  storycard062: storycard062,
  storycard063: storycard063,

  storycard14: storycard14,
  storycard15: storycard15,
  storycard16: storycard16,
  storycard17: storycard17,
  storycard18: storycard18,
  backSlider: backSlider,
  teamSpirit: teamSpirit,
};
export const hospitality = {
  web1: web1,
  web2: web2,
  web3: web3,
  web4: web4,
  web5: web5,
  web6: web6,
  web7: web7,
  web8: web8,
  web9: web9,
  web10: web10,
  web11: web11,
  web12: web12,
  web13: web13,
  web14: web14,
  web15: web15,
  web16: web16,
  web17: web17,
  web18: web18,
};
export const contractor = {
  cons1: cons1,
  cons2: cons2,
  cons3: cons3,
  cons4: cons4,
  cons5: cons5,
  cons6: cons6,
  cons7: cons7,
  cons8: cons8,
  cons9: cons9,
  cons10: cons10,
  cons11: cons11,
  cons12: cons12,
  cons13: cons13,
  cons14: cons14,
  cons15: cons15,
  cons16: cons16,
};

export const logocards = {
  logocard1: logocard1,
  logocard2: logocard2,
  logocard3: logocard3,
  logocard4: logocard4,
  logocard5: logocard5,
  logocard6: logocard6,
  logocard7: logocard7,
  logocard8: logocard8,
  logocard9: logocard9,
  logocard10: logocard10,
  logocard11: logocard11,
  logocard12: logocard12,
  logocard13: logocard13,
  logocard14: logocard14,
  logocard15: logocard15,
  logocard16: logocard16,
  logocard17: logocard17,
  logocard18: logocard18,
  logocard19: logocard19,
  logocard20: logocard20,
  logocard21: logocard21,
  logocard22: logocard22,
  logocard23: logocard23,
  logocard24: logocard24,
  logocard25: logocard25,
  logocard26: logocard26,
  logocard27: logocard27,
  logocard28: logocard28,
  logocard29: logocard29,
  logocard30: logocard30,
  logocard31: logocard31,
  logocard32: logocard32,
  logocard33: logocard33,
  logocard34: logocard34,
  logocard35: logocard35,
  logocard36: logocard36,
  logocard37: logocard37,
  logocard38: logocard38,
  logocard39: logocard39,
  logocard40: logocard40,
  logocard41: logocard41,
  logocard42: logocard42,
  logocard43: logocard43,
  logocard44: logocard44,
  logocard45: logocard45,
  logocard46: logocard46,
  logocard47: logocard47,
  logocard48: logocard48,
  logocard49: logocard49,
  logocard50: logocard50,
  logocard51: logocard51,
  logocard52: logocard52,
  logocard53: logocard53,
  logocard54: logocard54,
  logocard55: logocard55,
  logocard56: logocard56,
  logocard57: logocard57,
  logocard58: logocard58,
  logocard59: logocard59,
  logocard60: logocard60,
  logocard61: logocard61,
  logocard62: logocard62,
  logocard63: logocard63,
  logocard64: logocard64,
  logocard65: logocard65,
  logocard66: logocard66,
  logocard67: logocard67,
  logocard68: logocard68,
  logocard69: logocard69,
  logocard70: logocard70,
  logocard71: logocard71,
  logocard72: logocard72,
  logocard73: logocard73,
  logocard74: logocard74,
  logocard75: logocard75,
  logocard76: logocard76,
  logocard77: logocard77,
  logocard78: logocard78,
  logocard79: logocard79,
  logocard80: logocard80,
  logocard81: logocard81,
  logocard82: logocard82,
  logocard83: logocard83,
  logocard84: logocard84,
  logocard85: logocard85,
  logocard86: logocard86,
  logocard87: logocard87,
  logocard88: logocard88,
  logocard89: logocard89,
  logocard90: logocard90,
  logocard91: logocard91,
  logocard92: logocard92,
  logocard93: logocard93,
  logocard94: logocard94,
  logocard95: logocard95,
  logocard96: logocard96,
  logocard97: logocard97,
  logocard98: logocard98,
};

export const mobilecards = {
  gym: gym,
  img2: img2,
  img3: img3,
  img4: img4,
  img5: img5,
  img6: img6,
  img7: img7,
  img8: img8,
  img9: img9,
  img10: img10,
  img11: img11,
  img12: img12,
  img13: img13,
  img15: img15,
  img16: img16,
  img17: img17,
  img18: img18,
  img19: img19,
  img20: img20,
  img21: img21,
  img22: img22,
};

export const blogcards = {
  blog1: blog1,
  blog2: blog2,
  blog3: blog3,
  blog4: blog4,
  blog5: blog5,
  blog6: blog6,
  blog7: blog7,
  blog8: blog8,
  blog9: blog9,
  blog10: blog10,
  blog11: blog11,
  blog12: blog12,
  blog13: blog13,
  blog14: blog14,
  blog15: blog15,
  blog16: blog16,
  blog17: blog17,
  blog18: blog18,
  blog19: blog19,
  blog20: blog20,
  blog21: blog21,
  blog22: blog22,
  blog23: blog23,
  blog24: blog24,
  blog25: blog25,
  blog26: blog26,
  blog27: blog27,
  blog28: blog28,
  blog29: blog29,
  blog30: blog30,
  blog31: blog31,
  blog32: blog32,
  blog33: blog33,
  blog34: blog34,
  blog35: blog35,
  blog36: blog36,
  blog37: blog37,
  blog38: blog38,
  blog39: blog39,
  blogA: blogA,
  blogB: blogB,
};
