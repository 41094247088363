import React from "react";
import { Route, Routes } from "react-router-dom";

const LabelComponent = () => {
  return <></>;
};

const Workwithus = () => {
  return (
    <>
      <div className="workwithUs-section h-center max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
        <div className="work-content flex-column v-center text-center ">
          <label className="large text-white workLabel">WORK WITH US</label>
          <h5 className="fw-medium">
            We would love to hear more about your project
          </h5>

          <div className="letstalk-anim">
            <label className="large fw-normal">
              {" "}
              <a style={{ color: "black" }} href="/contact-us">
                Let’s Talk
              </a>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Workwithus;
