import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import adidas from "../../assets/images/home/Frame 25.webp";
import basket from "../../assets/images/home/Frame 26.webp";
import bauer from "../../assets/images/home/Frame 27.webp";
import karma from "../../assets/images/home/Frame 28.webp";
import recording from "../../assets/images/home/Frame 29.webp";
import universal from "../../assets/images/home/Frame 30.webp";
import training from "../../assets/images/home/Frame 31.webp";
import music from "../../assets/images/home/Frame 32.webp";
import mTV from "../../assets/images/home/mTV.webp";
import mazda from "../../assets/images/home/mazda.webp";
import gap from "../../assets/images/home/gap.webp";
import integrity from "../../assets/images/home/integrity.webp";
import nike from "../../assets/images/home/nike.webp";
import keg from "../../assets/images/home/keg.webp";
import boss from "../../assets/images/home/boss.webp";
import YoungLiving from "../../assets/images/home/young-living.svg";
import Harley from "../../assets/images/home/harley.png";
import Harkins from "../../assets/images/home/harkins.svg";
import Eurostar from "../../assets/images/home/eurostar.svg";
import arch from "../../assets/images/home/arch.png";
import euroaccident from "../../assets/images/home/euro-accident.png";
import FHC from "../../assets/images/home/FHC.png";
import proteket from "../../assets/images/home/proteket.png";
import nfc from "../../assets/images/home/nfc.png";
import mirialiti from "../../assets/images/home/mirialiti.png";
import skroote from "../../assets/images/home/skroote.png";
import pocketdentist from "../../assets/images/home/pocket-dentist.png";
import neurogazer from "../../assets/images/home/neurogazer.png";
import systima from "../../assets/images/home/systima.svg";
import logo1 from "../../assets/images/home/logo1.png";
import logo2 from "../../assets/images/home/logo2.png";
import logo3 from "../../assets/images/home/logo3.png";
import logo4 from "../../assets/images/home/logo4.png";
import logo5 from "../../assets/images/home/logo5.png";
import logo6 from "../../assets/images/home/logo6.png";
import logo7 from "../../assets/images/home/logo7.png";
import logo8 from "../../assets/images/home/logo8.png";
import logo9 from "../../assets/images/home/logo9.png";
import logo10 from "../../assets/images/home/logo10.png";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Scrollbar, Autoplay } from "swiper/modules";

const Slider = () => {
  return (
    <div className="mx-auto-updateds-app-about-page max-w-8xl">
      <div className="team-slider">
        <div
          className="h1-updated "
          style={{
            paddingBottom: "90px",
            textAlign: "center",
          }}
        >
          Our Clients
        </div>
        <Swiper
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          scrollbar={{
            hide: false,
          }}
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Scrollbar, Navigation, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={arch} alt="img" className="cursor-pointer" />
            <img src={basket} alt="img" className="cursor-pointer" />
            <img src={bauer} alt="img" className="cursor-pointer" />
            <img src={karma} alt="img" className="cursor-pointer" />
            <img src={recording} alt="img" className="cursor-pointer" />
            <img src={universal} alt="img" className="cursor-pointer" />
            <img src={training} alt="img" className="cursor-pointer" />
            <img src={music} alt="img" className="cursor-pointer" />
            <img src={Eurostar} alt="img" className="cursor-pointer" />
            <img src={Harkins} alt="img" className="cursor-pointer" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={neurogazer} alt="img" className="cursor-pointer" />

            <img src={mazda} alt="img" className="cursor-pointer" />
            <img src={gap} alt="img" className="cursor-pointer" />
            <img src={integrity} alt="img" className="cursor-pointer" />
            <img src={recording} alt="img" className="cursor-pointer" />
            <img src={nike} alt="img" className="cursor-pointer" />
            <img src={keg} alt="img" className="cursor-pointer" />
            <img src={boss} alt="img" className="cursor-pointer" />
            <img src={Harley} alt="img" className="cursor-pointer" />
            <img src={YoungLiving} alt="img" className="cursor-pointer" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={adidas} alt="img" className="cursor-pointer" />
            <img src={euroaccident} alt="img" className="cursor-pointer" />
            <img src={nfc} alt="img" className="cursor-pointer" />
            <img src={FHC} alt="img" className="cursor-pointer" />
            <img src={proteket} alt="img" className="cursor-pointer" />

            <img src={systima} alt="img" className="cursor-pointer" />
            <img src={mirialiti} alt="img" className="cursor-pointer" />
            <img src={pocketdentist} alt="img" className="cursor-pointer" />
            <img src={skroote} alt="img" className="cursor-pointer" />
            <img src={mTV} alt="img" className="cursor-pointer" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={logo1} alt="img" className="cursor-pointer" />
            <img src={logo2} alt="img" className="cursor-pointer" />
            <img src={logo3} alt="img" className="cursor-pointer" />
            <img src={logo4} alt="img" className="cursor-pointer" />
            <img src={logo5} alt="img" className="cursor-pointer h-5 w-5" />

            <img src={logo6} alt="img" className="cursor-pointer" />
            <img src={logo7} alt="img" className="cursor-pointer" />
            <img src={logo8} alt="img" className="cursor-pointer" />
            <img src={logo9} alt="img" className="cursor-pointer" />
            <img src={logo10} alt="img" className="cursor-pointer" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
