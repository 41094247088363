import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import ScrollToTop from "../components/shared/scrollToTop";
// import { Helmet } from "react-helmet-async";

const ModalAudit = () => {
  // Inside AuditModal component
  // const handleClose = () => {
  //   document.getElementById("myIframe").style.display = "none";
  // };
  ScrollToTop();

  return (
    <>
      {/* <Helmet>
        <script
          defer
          src="https://online.seranking.com/frontend-dist/widget-manager/main.js"
        ></script>
        <script
          defer
          src="https://online.seranking.com/frontend-dist/Widgets/js/main.js"
          data-widget-type="modal"
          data-auto-open-time="0"
          data-widget-page-audit-id="3093869-2917"
        ></script>
      </Helmet> */}
      <div style={{ backgroundColor: "#f7f7fb", height: "100vh" }}></div>
    </>
  );
};

export default ModalAudit;
