import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profile from "../../assets/icons/updatedlogo.jpeg";
import comma from "../../assets/icons/commas.png";
import comma2 from "../../assets/icons/commas2.png";
import peter from "../../assets/icons/peter.png";
import paul from "../../assets/icons/paul.png";
import waldo from "../../assets/icons/waldo.png";

const data = [
  {
    id: 1,
    imageUrl: profile,
    name: "Johnny Bananas",
    company: "MTV Studios Celebrity",
    review:
      "Truly exceeded our expectations with their website development services. From the initial consultation to the final launch, their team exhibited a remarkable level of professionalism,  creativity expertise. We are thrilled with the end result and have received numerous compliments on the website's design and functionality. We wholeheartedly endorse Pulse Marketing for their exceptional website development services.",
    icon: comma2,
    iconhover: comma,
  },
  {
    id: 2,
    imageUrl: profile,
    name: "Peter Szaly",
    company: "Owner- Brilliant Electric",
    review:
      "I have been working with Pulse Marketing for several years now, and I can confidently say that they are the best in the business. Their team is dedicated, creative, and always willing to listen to our needs and concerns. They have helped  us build a strong brand identity and establish ourselves as leaders in our industry. I highly recommend Pulse Marketing to any business looking for a top-notch marketing agency.",
    icon: comma2,
    iconhover: comma,
  },
  {
    id: 3,
    imageUrl: profile,
    name: "Waldo Villeda",
    company: "Owner- F45 Fitness",
    review:
      "Pulse Marketing is a highly skilled and results-driven marketing agency. They have helped us build a strong digital presence and increase our online visibility through effective SEO and PPC campaigns. Their team is responsive, proactive, and always willing to go the extra mile to ensure our success. We highly recommend Pulse Marketing to any business looking to improve their online marketing efforts.",
    icon: comma2,
    iconhover: comma,
  },

  {
    id: 4,
    imageUrl: profile,
    name: "Paul Schipizky",
    company: "Owner- Brilliant Electric",
    review:
      "This team has been instrumental in our business growth. Pulse has helped us establish a strong social media presence and build relationships with our audience through engaging content and effective community management. They are highly skilled, professional, and always willing to go the extra mile. We highly recommend Pulse Marketing to any business looking to improve their social media marketing efforts.",
    icon: comma2,
    iconhover: comma,
  },
  // Add more customers as needed
];

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplay: false,
        },
      },
    ],
  };

  function formatReview(review, desiredLength) {
    if (review.length > desiredLength) {
      // If the review is too long, truncate it and add an ellipsis
      return review.substring(0, desiredLength - 3) + "...";
    } else {
      // If the review is too short, add invisible characters
      while (review.length < desiredLength) {
        // You can use an HTML entity such as `&zwnj;`, which is a zero-width non-joiner
        review += "\u200C"; // That's the unicode for the HTML entity &zwnj;
      }
      return review;
    }
  }

  // Map over the data to format each review
  function appendInvisibleText(review, additionalText) {
    return (
      <>
        {review}
        <span className="invisible-text">{additionalText}</span>
      </>
    );
  }

  const invisibleText = "aaaaaaaaaaaaaaa"; // Your extra text to hide
  const formattedData = data.map((customer) => ({
    ...customer,
    review: appendInvisibleText(customer.review, invisibleText),
  }));

  return (
    <div>
      <div className="ourClient max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
        <h2 className="fw-medium">
          <span>What Our</span> Clients Say
        </h2>
        {/* <div className="clientReview-slider">
          <Slider {...settings} className="d-nonse">
            <div className="for-layer">
              <div className="slide-1">
                <div className="top">
                  <div>
                    <img src={profile} alt="img" />
                  </div>
                  <div className="d-flex">
                    <img src={comma} alt="img" className="comma hover-hide" />
                    <img
                      src={comma2}
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p>
                  Truly exceeded our expectations with their website development
                  services. From the initial consultation to the final launch,
                  their team exhibited a remarkable level of professionalism,
                  creativity, and technical expertise. We are thrilled with the
                  end result and have received numerous compliments on the
                  website's design and functionality. We wholeheartedly endorse
                  Pulse Marketing for their exceptional website development
                  services.
                </p>
              </div>
              <div
                className="text-whitening"
                style={{ position: "absolute", bottom: "10px", right: "30px" }}
              >
                <p className="body-large fw-semibold">Johnny Bananas</p>
                <p className="">MTV Studios Celebrity</p>
              </div>
              <div className="layer"></div>
            </div>
            <div
              className="for-layer"
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <div className="slide-1">
                <div className="top">
                  <img src={profile} alt="img" />
                  <div className="d-flex">
                    <img src={comma} alt="img" className="comma hover-hide" />
                    <img
                      src={comma2}
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p>
                  I have been working with Pulse Marketing for several years
                  now, and I can confidently say that they are the best in the
                  business. Their team is dedicated, creative, and always
                  willing to listen to our needs and concerns. They have helped
                  us build a strong brand identity and establish ourselves as
                  leaders in our industry. I highly recommend Pulse Marketing to
                  any business looking for a top-notch marketing agency.{" "}
                </p>
              </div>

              <div
                className="text-whitening"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "30px",
                }}
              >
                <p className="body-large fw-semibold">Peter Szaly</p>
                <p className="designation">Owner - ABR Construction</p>
              </div>

              <div className="layer"></div>
            </div>
            <div className="for-layer">
              <div className="slide-1">
                <div className="top">
                  <img src={profile} alt="img" />
                  <div className="d-flex">
                    <img src={comma} alt="img" className="comma hover-hide" />
                    <img
                      src={comma2}
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p>
                  This team has been instrumental in our business growth. Pulse
                  has helped us establish a strong social media presence and
                  build relationships with our audience through engaging content
                  and effective community management. They are highly skilled,
                  professional, and always willing to go the extra mile. We
                  highly recommend Pulse Marketing to any business looking to
                  improve their social media marketing efforts.{" "}
                </p>
              </div>
              <div
                className="text-whitening"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "30px",
                }}
              >
                <p className="body-large fw-semibold">Paul Schipizky</p>
                <p className="designation">Owner- Brilliant Electric</p>
              </div>
              <div className="layer"></div>
            </div>
            <div className="for-layer">
              <div className="slide-1">
                <div className="top">
                  <img src={profile} alt="img" />
                  <div className="d-flex">
                    <img src={comma} alt="img" className="comma hover-hide" />
                    <img
                      src={comma2}
                      alt="img"
                      className="comma hover-show d-none"
                    />
                  </div>
                </div>
                <p>
                  Pulse Marketing is a highly skilled and results-driven
                  marketing agency. They have helped us build a strong digital
                  presence and increase our online visibility through effective
                  SEO and PPC campaigns. Their team is responsive, proactive,
                  and always willing to go the extra mile to ensure our success.
                  We highly recommend Pulse Marketing to any business looking to
                  improve their online marketing efforts.{" "}
                </p>
              </div>
              <div
                className="text-whitening"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "30px",
                }}
              >
                <p className="body-large fw-semibold">Waldo Villeda </p>
                <p className="designation">Owner- F45 Fitness</p>
              </div>{" "}
              <div className="layer"></div>
            </div>
          </Slider>
        </div> */}

        <div className="clientReview-slider">
          <Slider {...settings} className="d-nonse">
            {formattedData.map((customer) => (
              <div className="for-layer" key={customer.id}>
                <div
                  className="slide-1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <div className="top">
                    <div>
                      <img
                        src={customer.imageUrl}
                        alt="img"
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "60px",
                        }}
                      />
                    </div>
                    <div className="d-flex">
                      <img
                        src={customer.iconhover}
                        className="comma hover-hide"
                      />
                      <img
                        src={customer.icon}
                        alt="img"
                        className="comma hover-show d-none"
                      />
                    </div>
                  </div>
                  <p
                    className="review-text"
                    style={{ maxHeight: "150px", overflow: "auto" }}
                  >
                    {customer.review}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      alignItems: "end",
                    }}
                  >
                    <p className="body-large fw-semibold">{customer.name}</p>
                    <p className="">{customer.company}</p>
                  </div>
                </div>

                <div className="layer"></div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
