import React from "react";
import banner from "../../assets/images/boarding/boarding-banner.webp";
import bVideo from "../../assets/video/about/aboutBanner.mp4";

const BoardingBanner = () => {
  return (
    <div className="aboutBanner">
      <div className="boarding-banner d-flex v-center h-center">
        <h5>WEBSITE DEVELOPMENT ON-BOARDING</h5>
        {/* a */}
      </div>

      <div className="banner-video w-100 h-100 ">
        <video loop autoPlay playsInline muted preload="auto">
          <source src={bVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default BoardingBanner;
