import React from "react";

import { Icon } from "@iconify/react";

import bVideo from "../../assets/video/Globe-Video.mp4";

const WhyChoose = () => {
  return (
    <div className="">
      <div className="max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
        <div className="whyChoose d-flex v-center flex-column">
          <div className="two">
            <div className="left">
              <h1>why choose us?</h1>
            </div>
            <div className="right GlobeRes">
              <video
                preload="auto"
                loop
                autoPlay
                playsInline
                muted
                style={{ width: "700px" }}
              >
                <source src={bVideo} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="service-wrapper v-center flex-column ">
            <div className="background"></div>
            <a href="#card-section ">
              <div className="cursor-wrapper h-center v-center flex-column gap-4">
                <div className="g-circle "></div>
                <Icon
                  icon="bi:arrow-down"
                  width="42"
                  height="42"
                  className="down-arrow"
                  color="black"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
