import React from "react";

const CrmBanner = () => {
  return (
    <div>
      <div
        className="crmBanner d-flex v-center h-center"
        style={{ background: "black" }}
      >
        <div className="banner-video w-100 h-100  ">
          <video
            preload="auto"
            autoPlay
            loop
            muted
            playsInline
            src="/assets/crm-background.mp4"
            className="w-100 h-100 object-fit-cover"
          ></video>
        </div>
        <div className="banner-content d-flex v-center h-center flex-column">
          <h1>ALL-IN-ONE CRM</h1>
          <p className="paraupdated">
            Boost your leads, conversions, and customer loyalty with our
            powerful automation tools.
          </p>
          <button className="d-none">Try Free</button>
          <p className="trial d-none">
            14-day free trial. No credit card required.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CrmBanner;
