export const CHAT_WIDGET_STYLE = {
  "--chat-widget-primary-color": "#50a2d4",
  "--chat-widget-active-color": "#50a2d4",
  "--chat-widget-bubble-color": "#50a2d4",
};

export const LOCATION_ID = "bogHR1PsYHVej6WEbBzb";
export const AGENCY_NAME = "Pulse Marketing Inc.";
export const AGENCY_WEBSITE = "https://pulsemarketing.io/";
export const LOCALE = "en-us";
