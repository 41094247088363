import React, { useEffect, useState } from "react";
import { portfolio } from "../content/data";
import { hospitality } from "../content/data";
import { services } from "../content/data";
import { contractor } from "../content/data";

import Storycard from "../shared/storycard";
import ReactPaginate from "react-paginate";

const Successstories = (props) => {
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 6;
  useEffect(() => {
    setData(animatedWebsite);
    setTotalPages(Math.ceil(animatedWebsite.length / itemsPerPage));
  }, []);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = data.slice(startIndex, endIndex);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  return (
    <div>
      <div className="s_stories-section v-center flex-column">
        <h2 className={`text-center ${props.hide}`}>
          {props.header}
          <br />
          <span className="text-greenish-blue">{props.subheader}</span>
        </h2>

        <h5 className={`fw-bolder ${props.hide2}`}>{props.header}</h5>
        {/* <div>
          <Button
            text="All"
            width="150px"
            height="40px"
            className="btn-primary text-white "
            onClick={() => handleNavigation("/contact-us")}
          />
          <Button
            text="Websites"
            width="150px"
            height="40px"
            className="btn-primary text-white "
            onClick={() => handleNavigation("/contact-us")}
          />

          <Button
            text="Hospitality"
            width="150px"
            height="40px"
            className="btn-primary text-white "
            onClick={() => handleNavigation("/contact-us")}
          />

          <Button
            text="Contractor"
            width="150px"
            height="40px"
            className="btn-primary text-white "
            onClick={() => handleNavigation("/contact-us")}
          />

          <Button
            text="E-Commerce"
            width="150px"
            height="40px"
            className="btn-primary text-white "
            onClick={() => handleNavigation("/contact-us")}
          />

          <Button
            text=" App"
            width="150px"
            height="40px"
            className="btn-primary text-white "
            onClick={() => handleNavigation("/contact-us")}
          />
        </div> */}

        {/* <div className="S-wrapper my-md-5  ">
          {activeFilter === "First" ? (
            <div className="stories-wrapper">
              <Storycard
                heading="steel wavell"
                cardImg={portfolio.storycard1}
              />
              <Storycard heading="studio 9p" cardImg={portfolio.storycard2} />
              <Storycard
                heading="admire & amaze"
                cardImg={portfolio.storycard3}
              />
              <Storycard
                heading="bienvillecapital"
                cardImg={portfolio.storycard4}
              />
              <Storycard
                heading="clouarchitects"
                cardImg={portfolio.storycard5}
              />
              <Storycard
                heading="rumblestudios"
                cardImg={portfolio.storycard6}
              />
              <Storycard heading="oneupstudio" cardImg={portfolio.storycard7} />
              <Storycard heading="petertarka" cardImg={portfolio.storycard8} />
              <Storycard
                heading="amuse bouche"
                cardImg={portfolio.storycard9}
              />
            </div>
          ) : activeFilter === "Second" ? (
            <div className="stories-wrapper">
              <Storycard
                heading="thinkingbox"
                cardImg={portfolio.storycard10}
              />
              <Storycard heading="lesanimals" cardImg={portfolio.storycard11} />
              <Storycard heading="nancy" cardImg={portfolio.storycard12} />
              <Storycard heading="ohio" cardImg={portfolio.storycard13} />
              <Storycard heading="enabling" cardImg={portfolio.storycard14} />
              <Storycard heading="bowls" cardImg={portfolio.storycard15} />
              <Storycard heading="norebro" cardImg={portfolio.storycard16} />
              <Storycard heading="fitness" cardImg={portfolio.storycard17} />
              <Storycard heading="boost" cardImg={portfolio.storycard18} />
            </div>
          ) : (
            ""
          )}
        </div> */}

        <div className="S-wrapper my-md-5  ">
          <div className="stories-wrapper awais06">
            <Storycard data={subset} />
          </div>
        </div>
        <ReactPaginate
          pageCount={totalPages}
          onPageChange={handlePageChange}
          forcePage={currentPage}
          pageRangeDisplayed={2}
          className="light-grey-shadow pagination-react"
        />
      </div>
    </div>
  );
};

const animatedWebsite = [
  {
    category: "Animated",
    img: portfolio.storycard028,
    head: "Win Win Mediation",
    link: "https://ww.bearplex.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard029,
    head: "Taroko",
    link: "https://taroko.io/",
  },
  {
    category: "Animated",
    img: portfolio.storycard030,
    head: "NuVision",
    link: "#",
  },
  {
    category: "Animated",
    img: portfolio.storycard031,
    head: "Evolvere",
    link: "https://evolvere.co/",
  },
  {
    category: "Animated",
    img: portfolio.storycard032,
    head: "Meotech",
    link: "https://meotech.io/",
  },
  {
    category: "Animated",
    img: portfolio.storycard033,
    head: "Setosa Skin",
    link: "https://setosaskin.pk/",
  },
  {
    category: "Animated",
    img: portfolio.storycard034,
    head: "Green Valley Wellness",
    link: "https://gvwellness.ca/",
  },
  {
    category: "Animated",
    img: portfolio.storycard035,
    head: "Skipp",
    link: "https://www.skipp.dev/home",
  },
  {
    category: "Animated",
    img: portfolio.storycard036,
    head: "Richmond Staffing",
    link: "https://richmondstaffing.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard037,
    head: "U&T Services",
    link: "http://utservices.co.uk/",
  },
  {
    category: "Animated",
    img: portfolio.storycard038,
    head: "PCR Travel",
    link: "https://pcrtravel.ae/",
  },
  {
    category: "Animated",
    img: portfolio.storycard039,
    head: "TaxCube",
    link: "https://taxcube.com.au/",
  },
  {
    category: "Animated",
    img: portfolio.storycard040,
    head: "Senders",
    link: "https://www.senders.co/",
  },
  {
    category: "Animated",
    img: portfolio.storycard041,
    head: "Indisability - NDIS",
    link: "https://indisability.com.au/",
  },
  {
    category: "Animated",
    img: portfolio.storycard042,
    head: "32 Pearls",
    link: "https://32pearlsdental.com.au/",
  },
  {
    category: "Animated",
    img: portfolio.storycard043,
    head: "Burj Homes",
    link: "https://burjhomes.com.au/",
  },
  {
    category: "Animated",
    img: portfolio.storycard044,
    head: "Omni HR",
    link: " https://omnihr.co/",
  },
  {
    category: "Animated",
    img: portfolio.storycard045,
    head: "Youth Alive",
    link: "#",
  },
  {
    category: "Animated",
    img: portfolio.storycard046,
    head: "Hanan’s Bridal",
    link: "https://hanansbridal.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard047,
    head: "Metamorphic Designs",
    link: "https://metamorphic.ae/",
  },
  {
    category: "Animated",
    img: portfolio.storycard048,
    head: "Idea Construction & Contracting",
    link: "https://idea-cc.co/",
  },
  {
    category: "Animated",
    img: portfolio.storycard049,
    head: "GPS Coaching",
    link: "https://gpscoaching.ca/",
  },
  {
    category: "Animated",
    img: portfolio.storycard050,
    head: "Code11",
    link: "https://www.code11.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard051,
    head: "Uruloki Crypto Assets",
    link: "https://www.uruloki.app/",
  },
  {
    category: "Animated",
    img: portfolio.storycard052,
    head: "Hedgeroe Home",
    link: " https://hedgeroe.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard053,
    head: "Bunkerless Golf",
    link: "https://bunkerlessgolf.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard054,
    head: "Acquis Labs",
    link: "https://acquislabs.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard1,
    head: "steel wavell",
    link: "https://www.steelwavellc.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard2,
    head: "STUDIO 9P",
    link: "https://studio9p.com/en/",
  },
  {
    category: "Animated",
    img: portfolio.storycard3,
    head: "ADMIRE & AMAZE",
    link: "https://admireamaze.debijenkorf.nl/",
  },
  {
    category: "Animated",
    img: portfolio.storycard4,
    head: "BIENVILLECAPITAL",
    link: "https://www.bienvillecapital.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard5,
    head: "CLOUARCHITECTS",
    link: "https://www.clouarchitects.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard6,
    head: "RUMBLESTUDIOS",
    link: "https://rumblestudios.tv/",
  },
  {
    category: "Animated",
    img: portfolio.storycard7,
    head: "ONEUPSTUDIO",
    link: "https://www.oneupstudio.it/",
  },
  {
    category: "Animated",
    img: portfolio.storycard8,
    head: "PETERTARKA",
    link: "https://petertarka.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard013,
    head: "VILLAGE DENTAL",
    link: "https://www.villagedentaldtc.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard014,
    head: "MILL BRIDGE DENTISTRY",
    link: "https://millbridgedentistry.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard015,
    head: "DIGITAL LUXURY GROUP",
    link: "https://digitalluxurygroup.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard016,
    head: "BRANDON SCOTT REALTOR",
    link: "https://bscottrealtydmv.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard017,
    head: "ARMADILLO",
    link: "https://www.armadillo.one/",
  },
  {
    category: "Animated",
    img: portfolio.storycard018,
    head: "ENDODONTIC EXCELLENCE",
    link: "https://www.endodontistatlanta.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard019,
    head: "FUZELOGISTICS",
    link: "https://fuzelogistics.com/en/",
  },
  {
    category: "Animated",
    img: portfolio.storycard020,
    head: "AOFMAKEUP",
    link: "https://aofmakeup.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard021,
    head: "NHTIUSA",
    link: "https://nhtiusa.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard022,
    head: "WORKING HANDS",
    link: "https://workinghandsak.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard023,
    head: "BETTERTAX",
    link: "https://bettertax.org/",
  },
  {
    category: "Animated",
    img: portfolio.storycard024,
    head: "CANDY",
    link: "https://candyldn.com/projects/",
  },
  {
    category: "Animated",
    img: portfolio.storycard025,
    head: "IPSPECIALIST",
    link: "https://ipspecialist.net/",
  },
  {
    category: "Animated",
    img: portfolio.storycard026,
    head: "BRIDGE PARTNER",
    link: "https://www.bridge.partners/",
  },
  {
    category: "Animated",
    img: portfolio.storycard027,
    head: "POSH PICNIC",
    link: "https://theposhpicnicatx.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web1,
    head: "Pierre’s",
    link: "https://pierreslounge.ca/",
  },
  {
    category: "Hospitality",
    img: hospitality.web2,
    head: "WestOak",
    link: "https://westoakrestaurant.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web3,
    head: "twelve west",
    link: "https://twelvewest.ca/",
  },
  {
    category: "Hospitality",
    img: hospitality.web4,
    head: "karma",
    link: "https://karmalounge.ca/",
  },
  {
    category: "Hospitality",
    img: hospitality.web5,
    head: "mansion club",
    link: "https://mansionclub.ca/",
  },
  {
    category: "Hospitality",
    img: hospitality.web6,
    head: "after dark",
    link: "https://afterdarkhospitality.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web8,
    head: "INCITEINTERACTIVE",
    link: "https://inciteinteractive.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web7,
    head: "absolute dental",
    link: "https://absolutedental.com.au/",
  },
  {
    category: "Hospitality",
    img: hospitality.web9,
    head: "bridge way digital",
    link: "https://bridgewaydigital.com/",
  },

  {
    category: "Hospitality",
    img: hospitality.web10,
    head: "appletonluff",
    link: "https://idealagent.com/",
  },

  {
    category: "Hospitality",
    img: hospitality.web11,
    head: "ideal agent",
    link: "https://www.appletonluff.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web12,
    head: "Defence Counsel",
    link: "https://singaporecriminallawyers.com/",
  },

  {
    category: "Hospitality",
    img: hospitality.web13,
    head: "singapore family lawyers",
    link: "https://singaporefamilylawyers.com.sg/",
  },
  {
    category: "Hospitality",
    img: hospitality.web14,
    head: "COMPLIMENTARY CONSULTATION",
    link: "https://ocwillsandtrusts.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web15,
    head: "Morgan Legal group",
    link: "https://www.morganlegalny.com/contact-us/",
  },
  {
    category: "Hospitality",
    img: hospitality.web17,
    head: "dreseanryan",
    link: "https://dreseanryan.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web16,
    head: "senseilms",
    link: "https://senseilms.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web18,
    head: "shoprootscience",
    link: "https://www.shoprootscience.com/",
  },

  {
    category: "services",
    img: services.ser01,
    head: "Absolute Dental",
    link: "https://absolutedental.com.au",
  },
  {
    category: "services",
    img: services.ser02,
    head: "Aim Sparkk",
    link: "https://aimsparkk.com",
  },
  {
    category: "services",
    img: services.ser03,
    head: "Aphrodite Hair Studio",
    link: "https://aphroditehairstudio.ca",
  },
  {
    category: "services",
    img: services.ser04,
    head: "Articket BCN",
    link: "https://articketbcn.org",
  },
  {
    category: "services",
    img: services.ser06,
    head: "Better Tax",
    link: "https://bettertax.org",
  },
  {
    category: "services",
    img: services.ser07,
    head: "Brilliant Electric",
    link: "https://brilliantelectric.ca",
  },
  {
    category: "services",
    img: services.ser08,
    head: "Cactus Landscaping",
    link: "https://cactuslandscaping.ca",
  },
  {
    category: "services",
    img: services.ser09,
    head: "Candy London",
    link: "https://candyldn.com",
  },
  {
    category: "services",
    img: services.ser10,
    head: "Dulex Pro Painting",
    link: "https://dulexpropainting.ca",
  },
  {
    category: "services",
    img: services.ser11,
    head: "Experience NVE",
    link: "https://experiencenve.com",
  },
  {
    category: "services",
    img: services.ser12,
    head: "Fuzelogistics",
    link: "https://fuzelogistics.com/en",
  },
  {
    category: "services",
    img: services.ser13,
    head: "Glassfix Canada",
    link: "https://glassfixcanada.com",
  },
  {
    category: "services",
    img: services.ser14,
    head: "Kelowna Details",
    link: "https://kelownadetails.com",
  },
  {
    category: "services",
    img: services.ser15,
    head: "Michelle Marin",
    link: "https://michellemarin.com/work",
  },
  {
    category: "services",
    img: services.ser16,
    head: "Millbridge Dentistry",
    link: "https://millbridgedentistry.com",
  },
  {
    category: "services",
    img: services.ser17,
    head: "NHTI USA",
    link: "https://nhtiusa.com",
  },
  {
    category: "services",
    img: services.ser18,
    head: "Panda Patches",
    link: "https://pandapatches.com",
  },
  {
    category: "services",
    img: services.ser19,
    head: "Platinum Lotus",
    link: "https://platinumlotus.ca",
  },
  {
    category: "services",
    img: services.ser20,
    head: "Pulse Marketing",
    link: "https://pulsemarketing.io",
  },
  {
    category: "services",
    img: services.ser21,
    head: "Aim Spark",
    link: "https://saaqitechdeveloper.com/aim_spark",
  },
  {
    category: "services",
    img: services.ser22,
    head: "Craftsman",
    link: "https://saaqitechdeveloper.com/craftsman",
  },
  {
    category: "services",
    img: services.ser23,
    head: "Digital Agency 2",
    link: "https://saaqitechdeveloper.com/digitalagency2",
  },
  {
    category: "services",
    img: services.ser24,
    head: "Echo Digital Agency",
    link: "https://saaqitechdeveloper.com/echo-digital-agency",
  },
  {
    category: "services",
    img: services.ser25,
    head: "Photo Studio",
    link: "https://saaqitechdeveloper.com/photostudio",
  },
  {
    category: "services",
    img: services.ser26,
    head: "Photo 5",
    link: "https://saaqitechdeveloper.com/photo_5",
  },
  {
    category: "services",
    img: services.ser27,
    head: "Starline Solutions",
    link: "https://starline.solutions",
  },
  {
    category: "services",
    img: services.ser28,
    head: "Vibrascreener",
    link: "https://vibrascreener.com",
  },
  {
    category: "services",
    img: services.ser29,
    head: "Working Hands AK",
    link: "https://workinghandsak.com",
  },
  {
    category: "services",
    img: services.ser30,
    head: "Allied London",
    link: "https://www.alliedlondon.com",
  },
  {
    category: "services",
    img: services.ser31,
    head: "Armadillo",
    link: "https://www.armadillo.one",
  },
  {
    category: "services",
    img: services.ser32,
    head: "Bridge Partners",
    link: "https://www.bridge.partners",
  },
  {
    category: "services",
    img: services.ser33,
    head: "Endodontist Atlanta",
    link: "https://www.endodontistatlanta.com",
  },
  {
    category: "services",
    img: services.ser34,
    head: "Village Dental DTC",
    link: "https://www.villagedentaldtc.com",
  },
  {
    category: "services",
    img: services.ser35,
    head: "Windermere",
    link: "https://www.windermere.com",
  },
  {
    category: "services",
    img: services.ser36,
    head: "Zen Oasis Spa",
    link: "https://www.zenoasis-spa.com",
  },
];
export default Successstories;
