import React from "react";
import Resultcard from "../shared/resultcard";

const Results = () => {
  return (
    <div>
      <div className="results d-flex v-center h-center flex-column">
        <h2>Check Out Some of Our SEO Results</h2>
        <div className="for-griding">
          <Resultcard
            time={0.1}
            isCounter={true}
            heading="500,000+"
            content="Visits driven to clients’ websites annually"
          />
          <Resultcard
            isCounter={true}
            heading="5,000+"
            content="Pieces of content published annually"
          />
          <Resultcard
            isCounter={true}
            heading="3,000+"
            content="Sites audited each year"
          />
          <Resultcard
            isCounter={true}
            heading="4,000+"
            content="Campaigns ranked"
          />
          <Resultcard
            isCounter={true}
            heading="7,000+"
            content="Webpages optimized each year"
          />
          <Resultcard
            isCounter={true}
            heading="3,000+"
            content="SEO of pages done"
          />
        </div>
      </div>
    </div>
  );
};

export default Results;
