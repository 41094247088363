import React, { useEffect, useState } from "react";

const StoryCardItem = ({ src, portfolioSrc }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    if (src) {
      const img = new Image();
      img.src = src;
      img.onload = () => setLoaded(true);
    }
  }, [src]);

  return (
    <>
      {!loaded ? (
        <div className="PaginationLoader">
          <div className="loader storycard"></div>
        </div>
      ) : (
        <>
          <img
            src={src}
            alt="storycard1"
            className="storycard img-fluid"
            onLoad={() => setLoaded(true)}
            // style={loaded ? {} : { display: "none" }}
          />
          <img src={portfolioSrc} alt="backSlider" className="backSlider" />
        </>
      )}
    </>
  );
};

export default StoryCardItem;
