import React from "react";
// import pulse from "../../assets/video/home/pulse.mp4";
// import pulse2 from "../../assets/images/home/pulse2.webp";
import pulse2 from "../../assets/video/thumbnail.png";
import bVideo from "../../assets/video/12.mp4";

const Banner = () => {
  return (
    <div>
      <div className="h-banner pl-hero-banner">
        <div className="banner-content-wrapper  ">
          <div className="banner-content zIndex1 widthCenter">
            {/* <h1 className="text-white text-uppercase">
              <img src={pulse2} alt="img" /a>
            </h1> */}
            <h1 className="text-white">
              SALES<span className="break"></span> FOCUSED
              <span className="break"></span> MARKETING
              <span className="break"></span> AGENCY
            </h1>

            <p className="text-white mt-4">
              {/* Pulse Marketing is a  */}
              Driving revenue to your business.
              {/* with an objective to increase revenue for all of our clients Pulse */}
              {/* has served 8k+ happy customer and completed 25k+ projects over the */}
              {/* last 10+ years in business from fortune 500 giants to locally */}
              {/* owned businesses. */}
            </p>
          </div>
          {/* <div className="pulse-video">
            <video
              autoPlay
              loop
              muted
              playsInline
              className="w-100 h-100 object-cover"
            >
              <source src={pulse} type="video/mp4" />
            </video>
          </div> */}
          <div className="background-video">
            <video
              loop
              autoPlay
              playsInline
              muted
              preload="auto"
              poster={pulse2}
            >
              <source src={bVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
