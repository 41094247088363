import * as images from "../../components/Images/Images";

const sliderData = [
  {
    id: 1,
    title: "Featured work",
    number: "1",
    hash: "#",
    mainTitle: "IGNITE",
    description:
      "IGNITE, founded by Dan Bilzerian, is a global lifestyle brand that sets the gold standard in Nicotine and Spirits, driven by premium quality, innovation, and excellence. It caters to an active lifestyle with its superior product line.",
    imgSrc: images.fw6, // Assuming 'images' is an object containing your imports
    imgAlt: "DEFICIENCY MANAGEMENT Image",
    sliderClass: "swiper-slide-3f38 slider-6",
    divClass: "slider2 d-flex slider-6",
  },

  {
    id: 2,
    title: "Featured work",
    number: "2",
    hash: "#",
    mainTitle: "SKYLINE SOLUTIONS",
    description:
      "Skyline Deficiency Solutions is Canada's premier construction deficiency company. With over 15 years in the business we have created a system of deficiency rectification, we have helped tower after tower correcting almost any problem.",
    imgSrc: images.fw5,
    imgAlt: "SKYLINE Image",
    sliderClass: "swiper-slide-3f38 slider-5",
    divClass: "slider2 d-flex slider-5",
  },

  // {
  //   id: 3,
  //   title: "Featured work",
  //   number: "3",
  //   hash: "#",
  //   mainTitle: "Simpli RFP",
  //   description:
  //     "Simplifying government contracts to efficiently enable business growth.",
  //   imgSrc: images.f13,
  //   imgAlt: "Simpli RFP Image",
  //   sliderClass: "swiper-slide-3f38",
  //   divClass: "slider2 d-flex   simp",
  // },
  {
    id: 3,
    title: "Featured work",
    number: "3",
    hash: "#",
    mainTitle: "FITORU REVOLUTION",
    description:
      "At Fitoru, we believe everyone deserves to live vibrantly and feel great. We’ve helped thousands of people of all ages, body types and wellness pursuits unlock their full potential with cutting-edge nutraceuticals that make weight loss and healthy living effortless. We’re changing the way people like you get fit and stay strong. Are you ready to join the revolution?",
    imgSrc: images.fw7, // Ensure this matches the import from your images file
    imgAlt: "FITORU REVOLUTION Image",
    sliderClass: "swiper-slide-3f38 slider-7",
    divClass: "slider2 d-flex slider-7",
  },
  {
    id: 4,
    title: "Featured work",
    number: "4",
    hash: "#",
    mainTitle: "NEW EYE DEFY",
    description:
      "Enjoy all the benefits and get your glowiest skin yet at the best value, including custom rewards and savings!",
    imgSrc: images.fw8, // Ensure this is correctly imported from your images file
    imgAlt: "NEW EYE DEFY Image",
    sliderClass: "swiper-slide-3f38 slider-8",
    divClass: "slider2 d-flex slider-8",
  },
  {
    id: 5,
    title: "Featured work",
    number: "5",
    hash: "#",
    mainTitle: "DAN HAINES",
    description:
      "Dan Haines, a Vancouver-based photographer, excels in model photography and boasts a versatile portfolio spanning various genres. With a keen eye for detail and a commitment to excellence, Dan consistently delivers captivating imagery that resonates with audiences.",
    imgSrc: images.fw2,
    imgAlt: "DAN HAINES Image",
    sliderClass: "swiper-slide-3f38 slider-2",
    divClass: "slider2 d-flex slider-2",
  },
  {
    id: 6,
    title: "Featured work",
    number: "6",
    hash: "#",
    mainTitle: "HARBER LONDON",
    description:
      "We are Harber London, and we create honest, high quality, functional products to help you carry better. Our products are designed to reflect our unique point of view, our style, make the little stuff better, and we don’t stop until we get it right. We combine incredibly high skilled craftsmanship, a touch from the heart and the finest materials available.",
    imgSrc: images.fw9, // Ensure the images object has been correctly imported
    imgAlt: "HARBER LONDON Image",
    sliderClass: "swiper-slide-3f38 slider-9",
    divClass: "slider2 d-flex slider-9",
  },
  {
    id: 7,
    title: "Featured work",
    number: "7",
    hash: "#",
    mainTitle: "Steel Wave",
    description:
      "Steel represents our strength, discipline & experience—while Wave represents our fluidity, creativity, and drive to continually innovate. It’s the rare combination of these elements that defines SteelWave and the partnerships we forge.",
    imgSrc: images.fw3, // Make sure this is correctly linked to your imported images
    imgAlt: "CREATIVE BULLPEN Image",
    sliderClass: "swiper-slide-3f38 slider-3",
    divClass: "slider2 d-flex slider-3",
  },
  {
    id: 8,
    title: "Featured work",
    number: "8",
    hash: "#",
    mainTitle: "TIME DISCOVERED",
    description:
      "We're on a mission to make buying, selling, and investing in vintage watches more affordable, transparent, and meaningful. No matter your interest, wearing or collecting, something for yourself or a gift for a loved one, we're here to be your guide through the market.",
    imgSrc: images.fw10,
    imgAlt: "TIME DISCOVERED Image",
    sliderClass: "swiper-slide-3f38 slider-10",
    divClass: "slider2 d-flex slider-10",
  },
  {
    id: 9,
    title: "Featured work",
    number: "9",
    hash: "#",
    mainTitle: "ZYNTH 2023",
    description:
      "Zynth Events & Entertainment Inc. has over 10 exciting years of experience in the industry. Winning Runner Up for “Best Professional Dance Company,” in the Best of Vancouver 2023 Awards; they know what it takes to elevate your event to the next level.",
    imgSrc: images.fw1,
    imgAlt: "ZYNTH 2023 Image",
    sliderClass: "swiper-slide-3f38 slider-1",
    divClass: "slider2 d-flex slider-1",
  },
  {
    id: 10,
    title: "Featured work",
    number: "10",
    hash: "#",
    mainTitle: "JOON HAIRCARE",
    description:
      "Merging spices from our grandmother's kitchen with science from our father's haircare lab.",
    imgSrc: images.fw12,
    imgAlt: "JOON HAIRCARE Image",
    sliderClass: "swiper-slide-3f38 slider-12",
    divClass: "slider2 d-flex slider-12",
  },
  {
    id: 11,
    title: "Featured work",
    number: "11",
    hash: "#",
    mainTitle: "AQMA SKINCARE",
    description:
      "We care about your skin and in order to protect it from serious problems caused by artificial preservatives we use natural alternatives which keeps your skin glowing.",
    imgSrc: images.fw13,
    imgAlt: "AQMA SKINCARE Image",
    sliderClass: "swiper-slide-3f38 slider-13",
    divClass: "slider2 d-flex slider-13",
  },
  {
    id: 12,
    title: "Featured work",
    number: "12",
    hash: "#",
    mainTitle: "PIERRE’S",
    description: "Vancouver’s most exclusive lounge experience.",
    imgSrc: images.fw4,
    imgAlt: "PIERRE’S Image",
    sliderClass: "swiper-slide-3f38 slider-4",
    divClass: "slider2 d-flex slider-4",
  },
  {
    id: 13,
    title: "Featured work",
    number: "13",
    hash: "#",
    mainTitle: "CleverCoach",
    description:
      "CleverCoach is an intuitive web-based platform that connects teachers with students, using specialized tools for optimal matches based on various criteria. It features integrated admin and teacher portals.",
    imgSrc: images.f14,
    imgAlt: "CleverCoach Image",
    sliderClass: "swiper-slide-3f38 slider-14",
    divClass: "slider2 d-flex slider-14",
  },
  {
    id: 14,
    title: "Featured work",
    number: "14",
    hash: "#",
    mainTitle: "Burj Group",
    description:
      "Pulse collaborated with Burj Group, enhancing their personalized service delivery to meet client visions with excellence. Our joint effort ensured a bespoke approach to projects for world-class experiences.",
    imgSrc: images.f15,
    imgAlt: "Burj Group Image",
    sliderClass: "swiper-slide-3f38 slider-15",
    divClass: "slider2 d-flex slider-15",
  },
  // {
  //   id: 16,
  //   title: "Featured work",
  //   number: "16",
  //   hash: "#",
  //   mainTitle: "Extended Trust",
  //   description:
  //     "Pulse crafted a specialized SaaS for ExtendedTrust, enhancing dealer management and enabling seamless resale of vehicle protection plans; simplifying operations for them and their dealers.",
  //   imgSrc: images.f16,
  //   imgAlt: "Extended Trust Image",
  //   sliderClass: "swiper-slide-3f38 slider-16",
  //   divClass: "slider2 d-flex slider-16",
  // },
  {
    id: 15,
    title: "Featured work",
    number: "15",
    hash: "#",
    mainTitle: "Signmail",
    description:
      "Pulse crafted SignMail, enhancing email communication for millions with customizable, interactive signatures; offering seamless integration, centralized management, boosting email engagement.",
    imgSrc: images.f17,
    imgAlt: "Signmail Image",
    sliderClass: "swiper-slide-3f38 slider-17",
    divClass: "slider2 d-flex slider-17",
  },
  {
    id: 16,
    title: "Featured work",
    number: "16",
    hash: "#",
    mainTitle: "EVOLVERE",
    description:
      "Rewire your brain in less than 20 minutes a day and break free from what’s holding you back with NeuroSync.",
    imgSrc: images.f18,
    imgAlt: "Hedgeroe Image",
    sliderClass: "swiper-slide-3f38 slider-18",
    divClass: "slider2 d-flex slider-18",
  },
  {
    id: 17,
    title: "Featured work",
    number: "17",
    hash: "#",
    mainTitle: "Swiss Gallery",
    description:
      "A premium ecommerce site by Pulse, based in Yorkville, specializing in watches & jewelry; highlighting Swiss Gallery's unique selection of luxury timepieces, ensuring a seamless experience for buyers & sellers.",
    imgSrc: images.f20,
    imgAlt: "SwissGallery Image",
    sliderClass: "swiper-slide-3f38 slider-20",
    divClass: "slider2 d-flex slider-20",
  },
];

export default sliderData;
