import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import QR1 from "../../assets/images/QR/QR1.svg";
import QR2 from "../../assets/images/QR/QR2.svg";
import QR3 from "../../assets/images/QR/QR3.svg";
import QR4 from "../../assets/images/QR/QR4.svg";
import QR5 from "../../assets/images/QR/QR5.svg";
import QR6 from "../../assets/images/QR/QR6.svg";
import QR7 from "../../assets/images/QR/Qr08.png";
import QR8 from "../../assets/images/QR/Qr09.webp";
import { useNavigate } from "react-router-dom";

const TestimonialsQr = () => {
  const navigate = useNavigate();

  const navigateToSection = (url, e) => {
    e.preventDefault();
    navigate(url);
    setTimeout(() => {
      window.scrollTo({
        top: document.querySelector(".pulse-navbar").offsetTop,
        behavior: "smooth",
      });
    }, 0);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div style={{ background: "black" }}>
      <div className="px-5 py-5 mx-auto-updateds-app-about-page max-w-7xl-updateds-app bg-black">
        <div className="ourClientQr">
          <div class="updated-pl">
            <div style={{ display: "flex" }} className="pb-2">
              <div className="ai-powered">AI Powered</div>
            </div>
            <h2 className="text-black-updateds text-white">
              Our Turnkey Services
            </h2>
            <p className="mt-2 text-white">
              Pulse Marketing Solutions for Every Step of Your Journey
            </p>
          </div>

          <div className="clientReview-slider">
            <Slider {...settings} className="d-nonse">
              <div className="for-qr-layer " style={{ width: "80px" }}>
                <div
                  className="slide-1 light-grey-shadow"
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.2)",
                    position: "relative", // Added relative positioning to slide-1
                  }}
                >
                  <a
                    href="/service/search-engine-optimization"
                    onClick={(e) =>
                      navigateToSection(
                        "/service/search-engine-optimization",
                        e
                      )
                    }
                    className="text-white"
                  >
                    <img
                      src={QR1}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
                <div className="seo-box-updates">
                  <a
                    href="/service/search-engine-optimization"
                    onClick={(e) =>
                      navigateToSection(
                        "/service/search-engine-optimization",
                        e
                      )
                    }
                    className="text-white"
                  >
                    <div className="text">Search Engine Optimization</div>
                  </a>
                </div>
              </div>
              <div className="for-qr-layer">
                <div
                  className="slide-1 light-grey-shadow"
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    boxShadow: "0px 0px 10px 10px rgba(0,0,0,0.2)",
                  }}
                >
                  <a
                    href="/service/web-development"
                    onClick={(e) =>
                      navigateToSection("/service/web-development", e)
                    }
                    className="text-white"
                  >
                    <img
                      src={QR2}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
                <div className="seo-box-updates">
                  <a
                    href="/service/web-development"
                    onClick={(e) =>
                      navigateToSection("/service/web-development", e)
                    }
                    className="text-white"
                  >
                    <div className="text">Web Design & Development</div>
                  </a>
                </div>
              </div>
              <div className="for-qr-layer">
                <div
                  className="slide-1 light-grey-shadow"
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    boxShadow: "0px 0px 10px 10px rgba(0,0,0,0.2)",
                  }}
                >
                  <a
                    href="/photography_videography"
                    onClick={(e) =>
                      navigateToSection("/photography_videography", e)
                    }
                    className="text-white"
                  >
                    <img
                      src={QR4}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
                <div className="seo-box-updates">
                  <a
                    href="/photography_videography"
                    onClick={(e) =>
                      navigateToSection("/photography_videography", e)
                    }
                    className="text-white"
                  >
                    <div className="text">Photography & Videography</div>
                  </a>
                </div>
              </div>
              <div className="for-qr-layer">
                <div
                  className="slide-1 light-grey-shadow"
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    boxShadow: "0px 0px 10px 10px rgba(0,0,0,0.2)",
                  }}
                >
                  <a
                    href="/service/orm-online-reputation-management"
                    onClick={(e) =>
                      navigateToSection(
                        "/service/orm-online-reputation-management",
                        e
                      )
                    }
                    className="text-white"
                  >
                    <img
                      src={QR5}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
                <div className="seo-box-updates">
                  <a
                    href="/service/orm-online-reputation-management"
                    onClick={(e) =>
                      navigateToSection(
                        "/service/orm-online-reputation-management",
                        e
                      )
                    }
                    className="text-white"
                  >
                    <div className="text">Online Reputation Management</div>
                  </a>
                </div>
              </div>

              <div className="for-qr-layer">
                <div
                  className="slide-1 light-grey-shadow"
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    boxShadow: "0px 0px 10px 10px rgba(0,0,0,0.2)",
                  }}
                >
                  <a
                    href="/service/branding-logos-design-graphic-design-packages"
                    onClick={(e) =>
                      navigateToSection(
                        "/service/branding-logos-design-graphic-design-packages",
                        e
                      )
                    }
                    className="text-white"
                  >
                    <img
                      src={QR3}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
                <div className="seo-box-updates">
                  <a
                    href="/service/branding-logos-design-graphic-design-packages"
                    onClick={(e) =>
                      navigateToSection(
                        "/service/branding-logos-design-graphic-design-packages",
                        e
                      )
                    }
                    className="text-white"
                  >
                    <div className="text">Branding Packages</div>
                  </a>
                </div>
              </div>

              <div className="for-qr-layer">
                <div
                  className="slide-1 light-grey-shadow"
                  style={{
                    borderRadius: "15px",
                    overflow: "hidden",
                    boxShadow: "0px 0px 10px 10px rgba(0,0,0,0.2)",
                  }}
                >
                  {" "}
                  <a
                    href="/service/applications-development"
                    onClick={(e) =>
                      navigateToSection("/service/applications-development", e)
                    }
                    className="text-white"
                  >
                    <img
                      src={QR6}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </a>
                </div>
                <div className="seo-box-updates">
                  <a
                    href="/service/applications-development"
                    onClick={(e) =>
                      navigateToSection("/service/applications-development", e)
                    }
                    className="text-white"
                  >
                    <div className="text">App Development</div>
                  </a>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsQr;
