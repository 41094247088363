import React, { useState } from "react";
import { logocards } from "../content/data";
import Logocards from "../shared/logocards";

const Branding = () => {
  const [activeFilter, setActiveFilter] = useState("First");
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };
  return (
    <div className="max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
      <div className="branding d-flex v-center flex-column">
        <div className="S-wrapper my-5">
          <div className="two-heads d-flex v-center h-center flex-column mx-auto-updateds max-w-7xl-updateds">
            <h1>Branding</h1>
            <h2>Branding </h2>
          </div>
          {activeFilter === "First" ? (
            <div className="stories-wrapper awais-11 mx-auto-updateds max-w-7xl-updateds">
              {/* <Logocards cardImg={logocards.logocard11} />
              <Logocards cardImg={logocards.logocard12} />
              <Logocards cardImg={logocards.logocard13} />
              <Logocards cardImg={logocards.logocard14} />
              <Logocards cardImg={logocards.logocard15} />
              <Logocards cardImg={logocards.logocard16} />
              <Logocards cardImg={logocards.logocard17} />
              <Logocards cardImg={logocards.logocard18} /> */}
              <Logocards cardImg={logocards.logocard59} />
              <Logocards cardImg={logocards.logocard60} />
              <Logocards cardImg={logocards.logocard61} />
              <Logocards cardImg={logocards.logocard62} />
              <Logocards cardImg={logocards.logocard63} />
              <Logocards cardImg={logocards.logocard64} />
              <Logocards cardImg={logocards.logocard65} />
              <Logocards cardImg={logocards.logocard66} />
              <Logocards cardImg={logocards.logocard67} />
              <Logocards cardImg={logocards.logocard68} />
              <Logocards cardImg={logocards.logocard69} />
              <Logocards cardImg={logocards.logocard70} />
              <Logocards cardImg={logocards.logocard71} />
              <Logocards cardImg={logocards.logocard72} />
              <Logocards cardImg={logocards.logocard73} />
              <Logocards cardImg={logocards.logocard74} />
              <Logocards cardImg={logocards.logocard75} />
              <Logocards cardImg={logocards.logocard76} />
              <Logocards cardImg={logocards.logocard77} />
              <Logocards cardImg={logocards.logocard78} />
              <Logocards cardImg={logocards.logocard79} />
              <Logocards cardImg={logocards.logocard80} />
              <Logocards cardImg={logocards.logocard81} />
              <Logocards cardImg={logocards.logocard82} />
              <Logocards cardImg={logocards.logocard83} />
              <Logocards cardImg={logocards.logocard84} />
              <Logocards cardImg={logocards.logocard85} />
              <Logocards cardImg={logocards.logocard86} />
              <Logocards cardImg={logocards.logocard87} />
              <Logocards cardImg={logocards.logocard88} />
              <Logocards cardImg={logocards.logocard89} />
              <Logocards cardImg={logocards.logocard90} />
              <Logocards cardImg={logocards.logocard91} />
              <Logocards cardImg={logocards.logocard92} />
              <Logocards cardImg={logocards.logocard93} />
              <Logocards cardImg={logocards.logocard94} />
              <Logocards cardImg={logocards.logocard95} />
              <Logocards cardImg={logocards.logocard96} />
              <Logocards cardImg={logocards.logocard97} />
              {/* <Logocards cardImg={logocards.logocard98} /> */}
            </div>
          ) : activeFilter === "Second" ? (
            <div className="stories-wrapper awais10">
              <Logocards cardImg={logocards.logocard1} />
              <Logocards cardImg={logocards.logocard2} />
              <Logocards cardImg={logocards.logocard3} />
              <Logocards cardImg={logocards.logocard4} />
              <Logocards cardImg={logocards.logocard5} />
              <Logocards cardImg={logocards.logocard6} />
              <Logocards cardImg={logocards.logocard7} />
              <Logocards cardImg={logocards.logocard8} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="pagination light-grey-shadow v-center h-center d-none">
          <p
            className={`body-large fw-bold next_icon pointer ${
              activeFilter === "First" ? "active" : ""
            }`}
            onClick={() => handleFilterClick("First")}
          >
            1
          </p>
          <p
            className={`body-large fw-bold next_icon pointer ${
              activeFilter === "Second" ? "active" : ""
            }`}
            onClick={() => handleFilterClick("Second")}
          >
            2
          </p>
          <p className="body-large fw-bold">3</p>
          <p className="body-large fw-bold">4</p>
          <p className="body-large fw-bold">5</p>
          <p className="body-large fw-bold">6</p>
          <p className="body-large fw-bold">7</p>
          <p className="body-large ">...</p>
          <p className="body-large ">16</p>
          {/* hello */}
        </div>
      </div>
    </div>
  );
};

export default Branding;
