import React from "react";

const JoinBanner = () => {
  return (
    <div className="pl-hero-banner">
      <div className="join-banner d-flex v-center h-center">
        <div className="join-video  w-100 h-100">
          <video
            preload="auto"
            autoPlay
            muted
            loop
            playsInline
            src="/assets/team.mp4"
            className="w-100 h-100 object-fit-cover"
          ></video>
        </div>
        <h5 className="h1-updated">JOIN THE TEAM </h5>
      </div>
    </div>
  );
};

export default JoinBanner;
