import React from "react";
import CounterComponent from "../../components/counter/counter";

const Democard = (props) => {
  return (
    <div>
      <div className="demoCard h-center">
        {props?.isCounter === true && parseInt(props.heading) >= 0 ? (
          <CounterComponent
            isCallFrom="demoCard"
            targetValue={parseInt(props.heading)}
            letterPart={props.heading?.replace(parseInt(props.heading), "")}
            time={60}
          />
        ) : (
          <h4 className="text-white  h-center ">{props.heading}</h4>
        )}
        <p className="text-white body-large fw-medium h-center">
          {props.content}
        </p>
        <div className="circle-anim"></div>
      </div>
    </div>
  );
};

export default Democard;
