import React, { Suspense } from "react";
import Democard from "../shared/democard";
import { aboutImg } from "../content/data";
import LazyLoadedImage from "../shared/lazyLoadedImage";

const WhyweRock = () => {
  return (
    <>
      <div className="whyRockSection">
        {/* awais */}
        <div className="achievement-card-wrapper max-w-7xl-updateds mx-auto-updateds-app-about-page">
          <Democard
            isCounter={true}
            heading="25K+"
            content="Projects Completed"
          />
          <Democard isCounter={true} heading="8K+" content="Happy Customers" />
          <Democard
            isCounter={true}
            heading="10"
            content="Years Of Experience"
          />
          <Democard isCounter={true} heading="20+" content="Countries Served" />
        </div>
        <div className="maincontent-div flex-column v-center">
          <p className="text-center">
            Empowering brands with a comprehensive digital suite—from immersive
            3D web development, SEO excellence, and captivating photos
            Videography for distinctive logo design and online reputation
            management, and bespoke application development, crafting a holistic
            online presence that drives engagement and success.
          </p>
        </div>
      </div>
      <div style={{ background: "#fafafa" }}>
        <div className="max-w-7xl-updateds mx-auto-updateds-app">
          <div className="rock-content d-flex v-center gap-5">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "start",
              }}
            >
              <h1 style={{ textAlign: "start" }} className="h1-updated-rock">
                Why We Rock
              </h1>

              <p>
                {/* Welcome to Pulse Marketing Inc. a Vancouver-based marketing firm
                that has been delivering exceptional services to businesses of
                all sizes for years. We have proudly served over 8,000 clients
                across various industries and have completed more than 25,000
                projects. */}
                Welcome to Pulse Marketing Inc, a Vancouver-based marketing firm
                with years of experience serving over 8,000 clients from various
                industries. We've successfully completed more than 25,000
                projects, offering comprehensive services from AI consulting,
                website and app development, ORM, branding, to digital marketing
                and more.
                <br />
                <br />
                {/* At Pulse Marketing, we believe that every business
                deserves access to quality marketing services. That's why we
                provide a wide range of services to help our clients achieve
                their goals. Our services include AI consulting, website and app
                development, content creation, ORM (online reputation
                management) branding, digital marketing, social media marketing,
                SEO, content marketing, and more. */}
                Our highly skilled team applies the latest marketing strategies
                to meet our clients’ goals, including increasing brand
                awareness, lead generation and sales growth.
                <br />
                <br />
                {/* Our team of experts is highly skilled and experienced in
                the latest marketing strategies and techniques. We use our
                knowledge to help our clients achieve their marketing
                objectives, whether that's increasing brand awareness,
                generating leads, or driving sales. */}
                We stand for fostering enduring relationships with our clients.
                By understanding their unique needs, we tailor strategies to
                achieve specific goals and ensure optimal results.
                <br />
                <br />
                {/* At Pulse Marketing, we believe in building long-lasting
                relationships with our clients. We take the time to understand
                our clients' unique needs and tailor our services to meet their
                specific goals. We work closely with our clients to ensure that
                we're delivering the results they need to succeed. */}
                Thanks for considering Pulse Marketing Inc. for your marketing
                needs. We are committed to helping you reach your goals and
                propelling your business forward.
                {/* <br />
                <br /> Thank you for considering Pulse Marketing Inc. for your
                marketing needs. We are dedicated to helping you achieve your
                goals and taking your business to the next level. */}
              </p>
            </div>
            {/* <img
              src={aboutImg.rock3d}
              alt="pulse logo"
              className="img-fluid"
              style={{ height: "600px" }}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyweRock;
