import React from "react";
import bVideo from "../../assets/video/4.mp4";

const SeoBanner = () => {
  return (
    <div className="aboutBanner v-center h-center">
      <div className="seo-banner d-flex v-center h-center flex-column banner-responsive ">
        <h1>SEO</h1>
        <h5>Skyrocket Your Growth</h5>
      </div>

      <div className="banner-video w-100 h-100 ">
        <video loop autoPlay playsInline muted preload="auto">
          <source src={bVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default SeoBanner;
