import React, { Suspense } from "react";

import { Icon } from "@iconify/react";
import ScrollToTop from "../components/shared/scrollToTop";
import Portfoliobanner from "../components/portfolio/portfoliobanner";
import AuditModal from "./auditmodal";
import LazyLoadedImage from "../components/shared/lazyLoadedImage";
import { aboutImg } from "../components/content/data";

const Audit = () => {
  ScrollToTop();

  return (
    <div>
      <Portfoliobanner
        heading="SEO AUDIT"
        para="Unlock Your SEO Potential and Attract the Perfect Customers and High-Value Leads from Google."
      />
      <div className="audit">
        <div className="content">
          <div className="bottom d-flex v-center h-center">
            <div className="hidden-scrollers">
              <AuditModal />
            </div>
            <div class="card-updateds">
              <div
                className="mx-auto-updated max-w-3xl"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <h2>Steps to Conduct an SEO Audit</h2>
                <p>
                  Begin by adding the specific web page URL you’d like to audit.
                </p>

                <p>
                  Carefully input the targeted keyword or phrase for which you
                  aim to optimize the page’s ranking. Remember, it’s crucial to
                  select only one precise keyword or phrase, like “Junk Removal”
                  or “Junk Removal Company”. Avoid entering a multitude of
                  keywords such as “Junk Removal Hauling Services Minneapolis
                  Minnesota”.
                </p>

                <p>
                  Drop in your contact details, and then run the audit process
                  and discover powerful insights!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Audit;
