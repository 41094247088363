import React, { useState } from "react";
import {
  portfolio,
  hospitality,
  contractor,
  ecommerce,
  restaurant,
  mobilecards,
  services,
  club,
  landingpage,
  realestate,
} from "../content/data";
import Storycard from "../shared/storycard";
import Button from "../shared/button";
import Mobilecard from "../shared/mobilecard";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import Applications from "../app-development/applications";

const Projects = (props) => {
  const [filter, setFilter] = useState("Animated");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9;

  useEffect(() => {
    setData(portfolioItems);
  }, []);

  const filteredData =
    filter === "All" ? data : data.filter((item) => item.category === filter);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const subset = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleFilter = (category) => {
    setFilter(category);
    setCurrentPage(0);
  };
  return (
    <div>
      <div
        className={
          filter === "Apps"
            ? "applications v-center flex-column"
            : "s_stories-section v-center flex-column"
        }
      >
        <h2 className={`text-center ${props.hide}`}>
          {props.header}
          <br />
          <span className="text-greenish-blue">{props.subheader}</span>
        </h2>
        <h5 className={`fw-bolder ${props.hide2}`}>{props.header}</h5>
        <div className="button-container">
          <div className="cat-btn">
            <Button
              text="All"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black d-none ${
                filter === "All" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("All")}
            />
            <Button
              text="Animated"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "Animated" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("Animated")}
            />
            <Button
              text="Hospitality"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "Hospitality" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("Hospitality")}
            />
            <Button
              text="Contractors
              "
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "contractorWebsite" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("contractorWebsite")}
            />
            <Button
              text="E-Commerce"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "ecommerceWebsite" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("ecommerceWebsite")}
            />
            <Button
              text="Restaurants"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "Restaurant" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("Restaurant")}
            />
            <Button
              text="Services"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "services" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("services")}
            />

            <br />

            <Button
              text="Clubs"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "club" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("club")}
            />
            <Button
              text="Landing Pages"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "landing-page" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("landing-page")}
            />
            <Button
              text="Real Estate"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "realestate" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("realestate")}
            />

            <Button
              text="Turnkey Sites"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "turnkeysites" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("turnkeysites")}
            />

            <Button
              text="Dispensaries"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "Dispensaries" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("Dispensaries")}
            />

            <Button
              text="Apps"
              width="150px"
              height="40px"
              className={`btn-primary2 text-black ${
                filter === "Apps" ? "selected text-white" : ""
              }`}
              onClick={() => handleFilter("Apps")}
            />
          </div>
        </div>

        <div className="S-wrapper my-md-5  ">
          <div className="stories-wrapper awais07">
            {filter === "Apps" ? (
              <Mobilecard data={mopbileApps} />
            ) : (
              <Storycard data={subset} />
            )}
          </div>
        </div>
        {filter === "Apps" ? (
          <></>
        ) : (
          <ReactPaginate
            pageCount={totalPages}
            onPageChange={handlePageChange}
            forcePage={currentPage}
            pageRangeDisplayed={2}
            className="light-grey-shadow pagination-react"
          />
        )}
      </div>
    </div>
  );
};

const portfolioItems = [
  {
    category: "Animated",
    img: portfolio.storycard1,
    head: "steel wavell",
    link: "https://www.steelwavellc.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard2,
    head: "STUDIO 9P",
    link: "https://studio9p.com/en/",
  },
  {
    category: "Animated",
    img: portfolio.storycard3,
    head: "ADMIRE & AMAZE",
    link: "https://admireamaze.debijenkorf.nl/",
  },
  {
    category: "Animated",
    img: portfolio.storycard4,
    head: "BIENVILLECAPITAL",
    link: "https://www.bienvillecapital.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard5,
    head: "CLOUARCHITECTS",
    link: "https://www.clouarchitects.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard6,
    head: "RUMBLESTUDIOS",
    link: "https://rumblestudios.tv/",
  },
  {
    category: "Animated",
    img: portfolio.storycard7,
    head: "ONEUPSTUDIO",
    link: "https://www.oneupstudio.it/",
  },
  {
    category: "Animated",
    img: portfolio.storycard8,
    head: "PETERTARKA",
    link: "https://petertarka.com/",
  },

  // turnkeysites

  {
    category: "turnkeysites",
    img: portfolio.storycard028,
    head: "Win Win Mediation",
    link: "https://ww.bearplex.com/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard029,
    head: "Taroko",
    link: "https://taroko.io/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard030,
    head: "NuVision",
    link: "#",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard031,
    head: "Evolvere",
    link: "https://evolvere.co/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard032,
    head: "Meotech",
    link: "https://meotech.io/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard033,
    head: "Setosa Skin",
    link: "https://setosaskin.pk/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard034,
    head: "Green Valley Wellness",
    link: "https://gvwellness.ca/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard035,
    head: "Skipp",
    link: "https://www.skipp.dev/home",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard036,
    head: "Richmond Staffing",
    link: "https://richmondstaffing.com/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard037,
    head: "U&T Services",
    link: "http://utservices.co.uk/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard038,
    head: "PCR Travel",
    link: "https://pcrtravel.ae/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard039,
    head: "TaxCube",
    link: "https://taxcube.com.au/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard040,
    head: "Senders",
    link: "https://www.senders.co/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard041,
    head: "Indisability - NDIS",
    link: "https://indisability.com.au/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard042,
    head: "32 Pearls",
    link: "https://32pearlsdental.com.au/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard043,
    head: "Burj Homes",
    link: "https://burjhomes.com.au/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard044,
    head: "Omni HR",
    link: " https://omnihr.co/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard045,
    head: "Youth Alive",
    link: "#",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard046,
    head: "Hanan’s Bridal",
    link: "https://hanansbridal.com/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard047,
    head: "Metamorphic Designs",
    link: "https://metamorphic.ae/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard048,
    head: "Idea Construction & Contracting",
    link: "https://idea-cc.co/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard049,
    head: "GPS Coaching",
    link: "https://gpscoaching.ca/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard050,
    head: "Code11",
    link: "https://www.code11.com/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard051,
    head: "Uruloki Crypto Assets",
    link: "https://www.uruloki.app/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard052,
    head: "Hedgeroe Home",
    link: " https://hedgeroe.com/",
  },
  {
    category: "turnkeysites",
    img: portfolio.storycard053,
    head: "Bunkerless Golf",
    link: "https://bunkerlessgolf.com/",
  },

  {
    category: "turnkeysites",
    img: portfolio.storycard054,
    head: "Acquis Labs",
    link: "https://acquislabs.com/",
  },
  {
    category: "Dispensaries",
    img: portfolio.storycard057,
    head: "6pak",
    link: "https://www.6pak.ca/",
  },
  {
    category: "Dispensaries",
    img: portfolio.storycard058,
    head: "atgpharma",
    link: "https://www.atgpharma.com/",
  },
  {
    category: "Dispensaries",
    img: portfolio.storycard063,
    head: "ignite",
    link: "https://ignite.co/",
  },
  {
    category: "Dispensaries",
    img: portfolio.storycard059,
    head: "cannapiece",
    link: "https://cannapiece.ca/",
  },
  {
    category: "Dispensaries",
    img: portfolio.storycard060,
    head: "ccg corp",
    link: "https://ccg-corp.ca/",
  },

  {
    category: "Dispensaries",
    img: portfolio.storycard061,
    head: "flowr",
    link: "https://flowr.ca/",
  },
  {
    category: "Dispensaries",
    img: portfolio.storycard062,
    head: "gvwellness",
    link: "https://gvwellness.ca/",
  },

  {
    category: "Animated",
    img: portfolio.storycard013,
    head: "VILLAGE DENTAL",
    link: "https://www.villagedentaldtc.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard014,
    head: "MILL BRIDGE DENTISTRY",
    link: "https://millbridgedentistry.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard015,
    head: "DIGITAL LUXURY GROUP",
    link: "https://digitalluxurygroup.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard016,
    head: "BRANDON SCOTT REALTOR",
    link: "https://bscottrealtydmv.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard017,
    head: "ARMADILLO",
    link: "https://www.armadillo.one/",
  },
  {
    category: "Animated",
    img: portfolio.storycard018,
    head: "ENDODONTIC EXCELLENCE",
    link: "https://www.endodontistatlanta.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard019,
    head: "FUZELOGISTICS",
    link: "https://fuzelogistics.com/en/",
  },
  {
    category: "Animated",
    img: portfolio.storycard020,
    head: "AOFMAKEUP",
    link: "https://aofmakeup.com/",
  },
  {
    category: "Animated",
    img: portfolio.storycard021,
    head: "NHTIUSA",
    link: "https://nhtiusa.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard022,
    head: "WORKING HANDS",
    link: "https://workinghandsak.com/",
  },

  {
    category: "Animated",
    img: portfolio.storycard023,
    head: "BETTERTAX",
    link: "https://bettertax.org/",
  },
  {
    category: "Animated",
    img: portfolio.storycard024,
    head: "CANDY",
    link: "https://candyldn.com/projects/",
  },
  {
    category: "Animated",
    img: portfolio.storycard025,
    head: "IPSPECIALIST",
    link: "https://ipspecialist.net/",
  },
  {
    category: "Animated",
    img: portfolio.storycard026,
    head: "BRIDGE PARTNER",
    link: "https://www.bridge.partners/",
  },
  {
    category: "Animated",
    img: portfolio.storycard027,
    head: "POSH PICNIC",
    link: "https://theposhpicnicatx.com/",
  },
  // Hospitality category
  {
    category: "Hospitality",
    img: hospitality.web1,
    head: "Pierre’s",
    link: "https://pierreslounge.ca/",
  },
  {
    category: "Hospitality",
    img: hospitality.web2,
    head: "WestOak",
    link: "https://westoakrestaurant.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web3,
    head: "twelve west",
    link: "https://twelvewest.ca/",
  },
  {
    category: "Hospitality",
    img: hospitality.web4,
    head: "karma",
    link: "https://karmalounge.ca/",
  },
  {
    category: "Hospitality",
    img: hospitality.web5,
    head: "mansion club",
    link: "https://mansionclub.ca/",
  },
  {
    category: "Hospitality",
    img: hospitality.web6,
    head: "after dark",
    link: "https://afterdarkhospitality.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web8,
    head: "INCITEINTERACTIVE",
    link: "https://inciteinteractive.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web7,
    head: "absolute dental",
    link: "https://absolutedental.com.au/",
  },
  {
    category: "Hospitality",
    img: hospitality.web9,
    head: "bridge way digital",
    link: "https://bridgewaydigital.com/",
  },

  {
    category: "Hospitality",
    img: hospitality.web10,
    head: "appletonluff",
    link: "https://idealagent.com/",
  },

  {
    category: "Hospitality",
    img: hospitality.web11,
    head: "ideal agent",
    link: "https://www.appletonluff.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web12,
    head: "Defence Counsel",
    link: "https://singaporecriminallawyers.com/",
  },

  {
    category: "Hospitality",
    img: hospitality.web13,
    head: "singapore family lawyers",
    link: "https://singaporefamilylawyers.com.sg/",
  },
  {
    category: "Hospitality",
    img: hospitality.web14,
    head: "COMPLIMENTARY CONSULTATION",
    link: "https://ocwillsandtrusts.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web15,
    head: "Morgan Legal group",
    link: "https://www.morganlegalny.com/contact-us/",
  },
  {
    category: "Hospitality",
    img: hospitality.web17,
    head: "dreseanryan",
    link: "https://dreseanryan.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web16,
    head: "senseilms",
    link: "https://senseilms.com/",
  },
  {
    category: "Hospitality",
    img: hospitality.web18,
    head: "shoprootscience",
    link: "https://www.shoprootscience.com/",
  },

  // contractorWebsite services

  {
    category: "services",
    img: services.ser01,
    head: "Absolute Dental",
    link: "https://absolutedental.com.au",
  },
  {
    category: "services",
    img: services.ser02,
    head: "Aim Sparkk",
    link: "https://aimsparkk.com",
  },
  {
    category: "services",
    img: services.ser03,
    head: "Aphrodite Hair Studio",
    link: "https://aphroditehairstudio.ca",
  },
  {
    category: "services",
    img: services.ser04,
    head: "Articket BCN",
    link: "https://articketbcn.org",
  },
  {
    category: "services",
    img: services.ser06,
    head: "Better Tax",
    link: "https://bettertax.org",
  },
  {
    category: "services",
    img: services.ser07,
    head: "Brilliant Electric",
    link: "https://brilliantelectric.ca",
  },
  {
    category: "services",
    img: services.ser08,
    head: "Cactus Landscaping",
    link: "https://cactuslandscaping.ca",
  },
  {
    category: "services",
    img: services.ser09,
    head: "Candy London",
    link: "https://candyldn.com",
  },
  {
    category: "services",
    img: services.ser10,
    head: "Dulex Pro Painting",
    link: "https://dulexpropainting.ca",
  },
  {
    category: "services",
    img: services.ser11,
    head: "Experience NVE",
    link: "https://experiencenve.com",
  },
  {
    category: "services",
    img: services.ser12,
    head: "Fuzelogistics",
    link: "https://fuzelogistics.com/en",
  },
  {
    category: "services",
    img: services.ser13,
    head: "Glassfix Canada",
    link: "https://glassfixcanada.com",
  },
  {
    category: "services",
    img: services.ser14,
    head: "Kelowna Details",
    link: "https://kelownadetails.com",
  },
  {
    category: "services",
    img: services.ser15,
    head: "Michelle Marin",
    link: "https://michellemarin.com/work",
  },
  {
    category: "services",
    img: services.ser16,
    head: "Millbridge Dentistry",
    link: "https://millbridgedentistry.com",
  },
  {
    category: "services",
    img: services.ser17,
    head: "NHTI USA",
    link: "https://nhtiusa.com",
  },
  {
    category: "services",
    img: services.ser18,
    head: "Panda Patches",
    link: "https://pandapatches.com",
  },
  {
    category: "services",
    img: services.ser19,
    head: "Platinum Lotus",
    link: "https://platinumlotus.ca",
  },
  {
    category: "services",
    img: services.ser20,
    head: "Pulse Marketing",
    link: "https://pulsemarketing.io",
  },
  {
    category: "services",
    img: services.ser21,
    head: "Aim Spark",
    link: "https://saaqitechdeveloper.com/aim_spark",
  },
  {
    category: "services",
    img: services.ser22,
    head: "Craftsman",
    link: "https://saaqitechdeveloper.com/craftsman",
  },
  {
    category: "services",
    img: services.ser23,
    head: "Digital Agency 2",
    link: "https://saaqitechdeveloper.com/digitalagency2",
  },
  {
    category: "services",
    img: services.ser24,
    head: "Echo Digital Agency",
    link: "https://saaqitechdeveloper.com/echo-digital-agency",
  },
  {
    category: "services",
    img: services.ser25,
    head: "Photo Studio",
    link: "https://saaqitechdeveloper.com/photostudio",
  },
  {
    category: "services",
    img: services.ser26,
    head: "Photo 5",
    link: "https://saaqitechdeveloper.com/photo_5",
  },
  {
    category: "services",
    img: services.ser27,
    head: "Starline Solutions",
    link: "https://starline.solutions",
  },
  {
    category: "services",
    img: services.ser28,
    head: "Vibrascreener",
    link: "https://vibrascreener.com",
  },
  {
    category: "services",
    img: services.ser29,
    head: "Working Hands AK",
    link: "https://workinghandsak.com",
  },
  {
    category: "services",
    img: services.ser30,
    head: "Allied London",
    link: "https://www.alliedlondon.com",
  },
  {
    category: "services",
    img: services.ser31,
    head: "Armadillo",
    link: "https://www.armadillo.one",
  },
  {
    category: "services",
    img: services.ser32,
    head: "Bridge Partners",
    link: "https://www.bridge.partners",
  },
  {
    category: "services",
    img: services.ser33,
    head: "Endodontist Atlanta",
    link: "https://www.endodontistatlanta.com",
  },
  {
    category: "services",
    img: services.ser34,
    head: "Village Dental DTC",
    link: "https://www.villagedentaldtc.com",
  },
  {
    category: "services",
    img: services.ser35,
    head: "Windermere",
    link: "https://www.windermere.com",
  },
  {
    category: "services",
    img: services.ser36,
    head: "Zen Oasis Spa",
    link: "https://www.zenoasis-spa.com",
  },
  // contractorWebsite club

  {
    category: "club",
    img: club.club01,
    head: "makeup",
    link: "https://aofmakeup.com/",
  },

  {
    category: "club",
    img: club.club02,
    head: "belush",
    link: "https://belushis.com/",
  },
  {
    category: "club",
    img: club.club03,
    head: "elite",
    link: "https://eliteceu.com/",
  },
  {
    category: "club",
    img: club.club04,
    head: "jarvis",
    link: "https://saaqitechdeveloper.com/jarvis1888/",
  },
  {
    category: "club",
    img: club.club05,
    head: "emojination",
    link: "https://saaqitechdeveloper.com/emojination106/",
  },
  {
    category: "club",
    img: club.club06,
    head: "hellix",
    link: "https://saaqitechdeveloper.com/hellix1020/",
  },
  {
    category: "club",
    img: club.club07,
    head: "huka",
    link: "https://saaqitechdeveloper.com/huka102/",
  },
  {
    category: "club",
    img: club.club08,
    head: "raw",
    link: "https://www.raw.co.uk/",
  },
  {
    category: "club",
    img: club.club09,
    head: "revolution bars",
    link: "https://www.revolution-bars.co.uk/",
  },
  // contractorWebsite landing-page

  {
    category: "landing-page",
    img: landingpage.lan01,
    head: "collectivegen",
    link: "https://collectivegen.com/",
  },

  {
    category: "landing-page",
    img: landingpage.lan02,
    head: "corporette",
    link: "https://corporette.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan03,
    head: "kayandtee",
    link: "https://kayandtee.ca/",
  },
  {
    category: "landing-page",
    img: landingpage.lan04,
    head: "brennannevadainc",
    link: "https://saaqitechdeveloper.com/brennannevadainc-final/",
  },
  // {
  //   category: "landing-page",
  //   img: landingpage.lan05,
  //   head: "brennannevadainc",
  //   link: "https://saaqitechdeveloper.com/brennannevadainc-final/",
  // },
  // {
  //   category: "landing-page",
  //   img: landingpage.lan06,
  //   head: "cowboyjerky",
  //   link: "https://saaqitechdeveloper.com/cowboyjerky/",
  // },
  {
    category: "landing-page",
    img: landingpage.lan07,
    head: "lp-finale",
    link: "https://saaqitechdeveloper.com/lp-finale/",
  },
  {
    category: "landing-page",
    img: landingpage.lan08,
    head: "theposhpicnicatx",
    link: "https://theposhpicnicatx.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan09,
    head: "vintagerevivals",
    link: "https://vintagerevivals.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan10,
    head: "epidemicsound",
    link: "https://www.epidemicsound.com/",
  },

  {
    category: "landing-page",
    img: landingpage.lan11,
    head: "hoopladigital",
    link: "https://www.hoopladigital.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan12,
    head: "productionbase",
    link: "https://www.productionbase.co.uk/",
  },
  {
    category: "landing-page",
    img: landingpage.lan13,
    head: "redproduction",
    link: "https://www.redproductioncompany.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan14,
    head: "teachjunkie",
    link: "https://www.teachjunkie.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan15,
    head: "phoblographer",
    link: "https://www.thephoblographer.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan16,
    head: "tunefind",
    link: "https://www.tunefind.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan17,
    head: "zynthentertain",
    link: "https://zynthentertainment.com/",
  },
  {
    category: "landing-page",
    img: landingpage.lan18,
    head: "plex",
    link: "https://www.plex.tv/",
  },
  // contractorWebsite real estate

  {
    category: "realestate",
    img: realestate.real01,
    head: "Scott REALTOR",
    link: "https://bscottrealtydmv.com/",
  },

  {
    category: "realestate",
    img: realestate.real02,
    head: "fam properties",
    link: "https://famproperties.com/",
  },
  {
    category: "realestate",
    img: realestate.real03,
    head: "ideal agent",
    link: "https://idealagent.com/",
  },
  {
    category: "realestate",
    img: realestate.real04,
    head: "knightfrank",
    link: "https://www.knightfrank.ae/",
  },
  {
    category: "realestate",
    img: realestate.real05,
    head: "arcatec",
    link: "https://saaqitechdeveloper.com/arcatec-101/",
  },
  {
    category: "realestate",
    img: realestate.real06,
    head: "hellix",
    link: "https://saaqitechdeveloper.com/hellix1020/",
  },
  {
    category: "realestate",
    img: realestate.real07,
    head: "better homes",
    link: "https://www.bhomes.com/en",
  },
  {
    category: "realestate",
    img: realestate.real08,
    head: "hilton hyland",
    link: "https://www.hiltonhyland.com/",
  },
  {
    category: "realestate",
    img: realestate.real09,
    head: "knight frank",
    link: "https://www.knightfrank.ae/",
  },
  // contractorWebsite category

  {
    category: "contractorWebsite",
    img: contractor.cons1,
    head: "skyline solutions",
    link: "https://skylinesolutions.ca/",
  },
  {
    category: "contractorWebsite",
    img: contractor.cons2,
    head: "starline",
    link: "https://starline.solutions/",
  },
  {
    category: "contractorWebsite",
    img: contractor.cons3,
    head: "tdr electric",
    link: "https://www.tdrelectric.ca/",
  },
  {
    category: "contractorWebsite",
    img: contractor.cons4,
    head: "glass fix",
    link: "https://www.glassfixcanada.com/",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons5,
    head: "bayut",
    link: "https://www.bayut.com/",
  },
  {
    category: "contractorWebsite",
    img: contractor.cons6,
    head: "famproperties",
    link: "https://famproperties.com/",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons7,
    head: "Betterhomes",
    link: "https://www.bhomes.com/en",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons8,
    head: "senseilms",
    link: "https://senseilms.com/",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons9,
    head: "out-class",
    link: "https://www.out-class.org/?gclid=EAIaIQobChMIztjxtvOF_QIVks53Ch0_hAMgEAAYASAAEgJxAfD_BwE",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons10,
    head: "orms systems",
    link: "https://ormsystems.ae/",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons11,
    head: "elevate creativeinc",
    link: "https://elevatecreativeinc.com/",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons12,
    head: "esseninternational",
    link: "https://esseninternational.com/",
  },
  {
    category: "contractorWebsite",
    img: contractor.cons13,
    head: "echoboomer design",
    link: "https://echoboomerdesign.com/",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons14,
    head: "vevs",
    link: "https://www.vevs.com/",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons15,
    head: "mo studio",
    link: "https://mo-studio.de/leistungen/",
  },

  {
    category: "contractorWebsite",
    img: contractor.cons16,
    head: "taleo-consulting",
    link: "https://www.taleo-consulting.com/",
  },

  // ecommerceWebsite restaurant
  {
    category: "Restaurant",
    img: restaurant.rest01,
    head: "belushis",
    link: "https://belushis.com/",
  },
  {
    category: "Restaurant",
    img: restaurant.rest02,
    head: "fazolis",
    link: "https://fazolis.com/",
  },
  {
    category: "Restaurant",
    img: restaurant.rest03,
    head: "donpeppe",
    link: "https://saaqitechdeveloper.com/donpeppe13n/",
  },
  {
    category: "Restaurant",
    img: restaurant.rest04,
    head: "foogra",
    link: "https://saaqitechdeveloper.com/foogra-1014/",
  },
  {
    category: "Restaurant",
    img: restaurant.rest05,
    head: "grillino",
    link: "https://saaqitechdeveloper.com/grillino14/",
  },
  {
    category: "Restaurant",
    img: restaurant.rest06,
    head: "laurent",
    link: "https://saaqitechdeveloper.com/laurent30n/",
  },
  {
    category: "Restaurant",
    img: restaurant.rest07,
    head: "the indian pantry",
    link: "https://saaqitechdeveloper.com/the_indian_pantry/",
  },
  {
    category: "Restaurant",
    img: restaurant.rest08,
    head: "alfanar restaurant",
    link: "https://www.alfanarrestaurant.com/",
  },
  {
    category: "Restaurant",
    img: restaurant.rest09,
    head: "derwandi",
    link: "https://www.derwandi.ae/",
  },

  // ecommerceWebsite category
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco1,
    head: "lead apparel",
    link: "https://leadapparel.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco2,
    head: "store holder",
    link: "https://store.holder.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco3,
    head: "oohlaluxe",
    link: "https://oohlaluxe.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco4,
    head: "fitoru",
    link: "https://fitoru.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco5,
    head: "innogear",
    link: "https://www.innogear.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco6,
    head: "dreamlandjewelry",
    link: "https://www.dreamlandjewelry.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco7,
    head: "bymilaner",
    link: "https://bymilaner.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco8,
    head: "goldfadenmd",
    link: "https://goldfadenmd.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco9,
    head: "yummie",
    link: "https://www.yummie.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco10,
    head: "harberlondon",
    link: "https://www.harberlondon.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco11,
    head: "allswellhome",
    link: "https://allswellhome.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco12,
    head: "veggieshake",
    link: "https://veggieshake.myshopify.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco13,
    head: "kenzaandco",
    link: "https://kenzaandco.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco14,
    head: "heartfuldiamonds",
    link: "https://www.heartfuldiamonds.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco15,
    head: "leo wild",
    link: "https://www.leoricci.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco16,
    head: "visualmood",
    link: "https://www.visualmood.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco17,
    head: "socaljax waxsd",
    link: "https://socaljaxwaxsd.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco18,
    head: "timeredis covered",
    link: "https://timerediscovered.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco19,
    head: "forg3dnutrition",
    link: "https://forg3dnutrition.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco20,
    head: "timerediscovered",
    link: "https://timerediscovered.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco21,
    head: "coffeecommitment",
    link: "https://coffeecommitment.myshopify.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco22,
    head: "joonhaircare",
    link: "https://joonhaircare.com",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco23,
    head: "aqmapro",
    link: "https://www.aqmapro.com",
  },
  // {
  //   category: "ecommerceWebsite",
  //   img: ecommerce.eco24,
  //   head: "joonhaircare",
  //   link: "https://joonhaircare.com",
  // },
  // {
  //   category: "ecommerceWebsite",
  //   img: ecommerce.eco25,
  //   head: "aqmapro",
  //   link: "https://www.aqmapro.com",
  // },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco26,
    head: "resplendentceylon",
    link: "https://www.resplendentceylon.com/",
  },

  // {
  //   category: "ecommerceWebsite",
  //   img: ecommerce.eco27,
  //   head: "esseninternational",
  //   link: "https://www.resplendentceylon.com/",
  // },

  {
    category: "ecommerceWebsite",
    img: ecommerce.eco28,
    head: "seafolly",
    link: "https://au.seafolly.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco29,
    head: "barking irons",
    link: "https://barkingironsroastery.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco30,
    head: "Blue Star",
    link: "https://bluestarcoffeeroasters.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco31,
    head: "coffee bean",
    link: "https://coffeebeansdelivered.com.au/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco32,
    head: "volley ball",
    link: "https://en.volleyballworld.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco33,
    head: "factor bikes",
    link: "https://factorbikes.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco34,
    head: "rudy cocktail",
    link: "https://jackrudycocktailco.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco35,
    head: "jade swim",
    link: "https://jadeswim.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco36,
    head: "joco cups",
    link: "https://jococups.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco37,
    head: "mindy weiss",
    link: "https://mindyweiss.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco38,
    head: "osana bar",
    link: "https://osanabar.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco39,
    head: "rv lifestyle",
    link: "https://rvlifestyle.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco40,
    head: "asma ayesha",
    link: "https://saaqitechdeveloper.com/asma-ayesha-01/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco41,
    head: "hazed edibles",
    link: "https://saaqitechdeveloper.com/hazed/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco42,
    head: "max brown",
    link: "https://saaqitechdeveloper.com/mb/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco43,
    head: "the indian pantry",
    link: "https://saaqitechdeveloper.com/the_indian_pantry/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco44,
    head: "mazda",
    link: "https://signaturemazda.uatlink.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco45,
    head: "sodashi",
    link: "https://sodashi.com.au/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco46,
    head: "little giants",
    link: "https://wearelittlegiants.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco47,
    head: "baseball monkey",
    link: "https://www.baseballmonkey.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco48,
    head: "subaru",
    link: "https://www.cars.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco49,
    head: "driven coffee",
    link: "https://www.drivencoffee.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco50,
    head: "henry j.",
    link: "https://www.henryjsocks.co.uk/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco51,
    head: "kiwivelo",
    link: "https://www.kiwivelo.co.nz/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco52,
    head: "mca store",
    link: "https://www.mcas.com.au/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco53,
    head: "holland coffee",
    link: "https://www.newhollandcoffee.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco54,
    head: "resplendent",
    link: "https://www.resplendentceylon.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco55,
    head: "sani swiss",
    link: "https://www.saniswiss.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco56,
    head: "rootscience",
    link: "https://www.shoprootscience.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco57,
    head: "soccer",
    link: "https://www.soccer.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco58,
    head: "societyoflifestyle",
    link: "https://www.societyoflifestyle.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco59,
    head: "striiiipes",
    link: "https://www.striiiipes.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco60,
    head: "tennis point",
    link: "https://www.tennis-point.co.uk/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco61,
    head: "yubico",
    link: "https://www.yubico.com/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco62,
    head: "zefal",
    link: "https://www.zefal.com/en/",
  },
  {
    category: "ecommerceWebsite",
    img: ecommerce.eco63,
    head: "zopdealer",
    link: "https://www.zopdealer.com/",
  },
  // appWebsite category (I noticed that you had the same data as ecommerceWebsite, so I'm including it again her)
  // {
  //   category: "appWebsite",
  //   img: mobilecards.gym,
  //   head: "lead apparel",
  //   link: "https://leadapparel.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img2,
  //   head: "store holder",
  //   link: "https://store.holder.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img3,
  //   head: "oohlaluxe",
  //   link: "https://oohlaluxe.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img4,
  //   head: "fitoru",
  //   link: "https://fitoru.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img5,
  //   head: "innogear",
  //   link: "https://www.innogear.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img6,
  //   head: "dreamlandjewelry",
  //   link: "https://www.dreamlandjewelry.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img7,
  //   head: "bymilaner",
  //   link: "https://bymilaner.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img8,
  //   head: "goldfadenmd",
  //   link: "https://goldfadenmd.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img9,
  //   head: "yummie",
  //   link: "https://www.yummie.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img10,
  //   head: "harberlondon",
  //   link: "https://www.harberlondon.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img11,
  //   head: "allswellhome",
  //   link: "https://allswellhome.com",
  // },
  // {
  //   category: "appWebsite",
  //   img: mobilecards.img12,
  //   head: "veggieshake",
  //   link: "https://veggieshake.myshopify.com",
  // },
];
const mopbileApps = [
  {
    app: mobilecards.gym,
    link: "#",
  },
  {
    app: mobilecards.img2,
    link: "#",
  },
  {
    app: mobilecards.img3,
    link: "#",
  },
  {
    app: mobilecards.img4,
    link: "#",
  },
  {
    app: mobilecards.img5,
    link: "#",
  },
  {
    app: mobilecards.img6,
    link: "#",
  },
  {
    app: mobilecards.img7,
    link: "#",
  },
  {
    app: mobilecards.img8,
    link: "#",
  },
  {
    app: mobilecards.img9,
    link: "#",
  },
  {
    app: mobilecards.img10,
    link: "#",
  },
  {
    app: mobilecards.img11,
    link: "#",
  },
  {
    app: mobilecards.img12,
    link: "#",
  },
  {
    app: mobilecards.img15,
    link: "#",
  },
  {
    app: mobilecards.img16,
    link: "#",
  },
  {
    app: mobilecards.img17,
    link: "#",
  },

  {
    app: mobilecards.img18,
    link: "#",
  },

  {
    app: mobilecards.img19,
    link: "#",
  },

  {
    app: mobilecards.img20,
    link: "#",
  },
  {
    app: mobilecards.img21,
    link: "#",
  },
  {
    app: mobilecards.img22,
    link: "#",
  },
  // {
  //   app: mobilecards.img15,
  //   link: "#",
  // },

  // {
  //   app: mobilecards.img13,
  //   link: "#",
  // },
];
export default Projects;
