import React, { useState } from "react";
import { portfolio } from "../content/data";
import StoryCardItem from "./storyCardItem";

const Storycard = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const data = props.data;
  const handleNavigation = (link) => {
    window.open(link, "_blank", "noreferrer");
  };

  return (
    <>
      {data.map((item, index) => {
        return (
          <div
            className="story-card pointer"
            key={index}
            onClick={() => handleNavigation(item.link)}
          >
            <div className="top light-grey-shadow">
              <h1 className="text-uppercase text-center">{item.head}</h1>

              <StoryCardItem
                src={item.img}
                portfolioSrc={portfolio.backSlider}
              />
            </div>
            <div className="bottom mt-3 text-center">
              <p className="text-uppercase fw-bolder ">{item.head}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Storycard;
