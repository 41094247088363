import React, { Suspense, useState, useCallback, memo } from "react";
import { useEffect, useRef } from "react";
import { logolink2 } from "../content/data";
import LazyLoadedImage from "./lazyLoadedImage";
import "../../style/main.scss";
import Accordion from "react-bootstrap/Accordion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { SplitText, chroma } from "../../plugins/index.js";
import { Link } from "react-router-dom";
import footerImage from "../../assets/images/footer/footer2.PNG";
import ArrowRight from "./../../assets/images/right-arrow.svg";
import backgroundImage from "./../../assets/images/SVGBGFOOTER.svg";
import GoogleReview from "../../assets/images/GoogleReviews.jpg";

const Footer = memo(() => {
  document.documentElement.style.setProperty(
    "--background-image",
    `url(${ArrowRight})`
  );

  const [selectedItem, setSelectedItem] = useState(
    localStorage.getItem("selectedItem") || "About Us"
  );

  // useEffect(() => {
  //   localStorage.setItem("selectedItem", selectedItem);
  // }, [selectedItem]);

  const handleSelectItem = useCallback((item) => {
    setSelectedItem(item);
  }, []);
  gsap.registerPlugin(ScrollTrigger);
  const menuAnim = useRef();

  useEffect(() => {
    if (menuAnim.current) {
      menuAnimation();
    }
  }, []);

  // const menuAnimation = () => {
  //   let rootParent = menuAnim.current.children;

  //   for (let i = 0; i < rootParent.length; i++) {
  //     let firstParent = rootParent[i].children;
  //     let arr = firstParent[0].textContent.split("");
  //     let spanData = "";
  //     for (let j = 0; j < arr.length; j++) {
  //       if (arr[j] == " ") {
  //         spanData += `<span style='width:6px;'>${arr[j]}</span>`;
  //       } else {
  //         spanData += `<span>${arr[j]}</span>`;
  //       }
  //     }
  //     let result = '<div class="menu-text">' + spanData + "</div>";
  //     firstParent[0].innerHTML = result;
  //   }
  // };
  useEffect(() => {
    if (typeof window !== "undefined") {
      let tHero = gsap.context(() => {
        let endTl = gsap.timeline({
          repeat: -1,
          delay: 0.5,
          scrollTrigger: {
            trigger: ".end",
            start: "bottom 100%-=50px",
          },
        });
        gsap.set(".end", {
          opacity: 0,
        });
        gsap.to(".end", {
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: ".end",
            start: "bottom 100%-=50px",
            once: true,
          },
        });
        let mySplitText = new SplitText(".end", { type: "words,chars" });
        let chars = mySplitText.chars;
        let endGradient = chroma.scale([
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",

          // "#F9D371",
          // "#F47340",
          // "#EF2F88",
          // "#8843F2",
        ]);
        endTl.to(chars, {
          duration: 0.5,
          scaleY: 0.6,
          ease: "power3.out",
          stagger: 0.04,
          transformOrigin: "center bottom",
        });
        endTl.to(
          chars,
          {
            yPercent: -20,
            ease: "elastic",
            stagger: 0.03,
            // duration: 0.8,
          }
          // 0.5
        );
        endTl.to(
          chars,
          {
            scaleY: 1,
            ease: "elastic.out(2.5, 0.2)",
            stagger: 0.03,
            duration: 1.5,
          },
          0.5
        );
        endTl.to(
          chars,
          {
            color: (i, el, arr) => {
              return endGradient(i / arr.length).hex();
            },
            ease: "power2.out",
            stagger: 0.03,
            // duration: 0.3,
          }
          // 0.5
        );
        endTl.to(
          chars,
          {
            yPercent: 0,
            ease: "back",
            stagger: 0.03,
            // duration: 0.8,
          }
          // 0.7
        );
        endTl.to(chars, {
          color: "#FFFFF",
          // color: "#c9f31d",
          duration: 1.4,
          stagger: 0.05,
        });
      });
      return () => tHero.revert();
    }
  }, []);

  return (
    <>
      <div>
        <div className="pulse-footer">
          <div className="lg-section-updated">
            <div className="nav-links-wrapper-update d-flex  flex-column   max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
              <div
                className="footer__contact-3  box-updated"
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  // Add other style properties as needed
                }}
              >
                <Link
                  className="end lets-updated"
                  to="/contact-us"
                  style={{ color: "white", wordSpacing: "1px" }}
                >
                  LET’S TALK
                </Link>
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="end">
                    BOOK YOUR FREE CONSULTATION WITH YOUR <br /> PULSE MARKETING
                    EXPERT TODAY!
                  </p>
                  <Link to="/contact-us">
                    <button
                      type="button"
                      className="py-3 px-3"
                      style={{ borderRadius: "15px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <path
                          d="M8.33325 20H31.6666"
                          stroke="black"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20 8.33333L31.6667 20L20 31.6667"
                          stroke="black"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "50px" }}>
            <div className="container md-section-updated">
              <Link
                to="/contact-us"
                className="link-button"
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <button
                  className="d-flex-updated v-center justify-content-between"
                  style={{ background: "transparent", padding: "15px" }}
                >
                  <Link to="/contact-us">
                    <div className="text-content-updated">
                      <div style={{ fontSize: "60px" }}>LET’S TALK</div>
                      <div>
                        BOOK YOUR FREE CONSULTATION WITH YOUR <br /> PULSE
                        MARKETING EXPERT TODAY!
                      </div>
                    </div>
                  </Link>

                  <button
                    type="button"
                    className="py-3 px-3"
                    style={{ borderRadius: "15px" }}
                  >
                    <Link to="/contact-us">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <path
                          d="M8.33325 20H31.6666"
                          stroke="black"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20 8.33333L31.6667 20L20 31.6667"
                          stroke="black"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Link>
                  </button>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="" style={{ background: "black" }}>
          <div className="pulse-footer d-flex max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
            <div className="logo-content-wrapper ">
              {/* <Suspense fallback="Loading...">
              <LazyLoadedImage
                src={logoLg}
                alt="pulse logo"
                className="img-fluid pb-4"
              />
            </Suspense> */}
              <div className="mb-4">
                <img
                  src={footerImage}
                  alt="Footer Logo"
                  loading="lazy"
                  className="lazyloaded"
                />
              </div>

              <p>
                Pulse Marketing is a sales-focused marketing firm based in
                Vancouver, British Columbia, with the objective of increasing
                revenue for all of our clients. Pulse has served 8k+ happy
                customers and completed 25k+ projects over the last 10+ years in
                business, from Fortune 500 giants to locally owned businesses.
              </p>

              <a
                href="https://www.bbb.org/ca/bc/vancouver/profile/digital-marketing/pulse-marketing-inc-0037-2428735/#sealclick"
                target="_blank"
              >
                <Suspense fallback="Loading...">
                  <LazyLoadedImage
                    src={logolink2}
                    alt="pulse logo"
                    className=" img-fluid pb-4  "
                  />
                </Suspense>
              </a>
              <br />

              <a
                href="https://g.co/kgs/AXhY1NL
              "
                target="_blank"
              >
                {/* <Suspense fallback="Loading..."> */}
                <div
                  style={{
                    width: "160px",
                    height: "65px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px 0px #000000",
                    padding: "10px",
                    // margin: "10px",
                    background: "white",
                    border: "1px solid #000000",
                    objectFit: "contain",
                  }}
                >
                  <img
                    src={GoogleReview}
                    alt="pulse logo"
                    className=" img-fluid"
                    style={{
                      height: "50px",
                      width: "150px",
                      objectFit: "contain",
                    }}
                  />
                </div>

                {/* </Suspense> */}
              </a>
            </div>
            {/* 
            <div className="bottom-links mt-5 pt-3">
              <a href="">Terms & conditions</a>
              <a href="">Privacy Policy</a>
              <a href="/about-us">About Us</a>
              <a href="/our-services">Services</a>
              <a href="/Faq">FAQ</a>
            </div> */}

            <div className="nav-links-wrapper d-flex flex-column">
              <Accordion activeKey={["0", "1", "3"]} className="Updatedflexbox">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>Explore</h5>
                  </Accordion.Header>
                  <hr style={{ borderColor: "rgb(156 163 175)" }} />
                  <Accordion.Body>
                    <ul className="nav flex-column text-white cursor-pointer-updated">
                      <li
                        className={
                          selectedItem === "ABOUT US"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("ABOUT US")}
                      >
                        <div className="pb-1 text-lg-updated ">
                          <Link
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated "
                            to="about-us"
                          >
                            ABOUT US
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "JOIN THE TEAM"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("JOIN THE TEAM")}
                      >
                        <div className="pb-1 text-lg-updated">
                          <Link
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                            to="join-the-team"
                          >
                            JOIN THE TEAM
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "OUR BLOGS"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("OUR BLOGS")}
                      >
                        <div className="pb-1 text-lg-updated">
                          <Link
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                            to="our-blogs"
                          >
                            {" "}
                            OUR BLOGS
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "ON-BOARDING"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("ON-BOARDING")}
                      >
                        <div className="pb-1 text-lg-updated">
                          <Link
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                            to="on-boarding"
                          >
                            {" "}
                            ON-BOARDING
                          </Link>
                        </div>
                      </li>

                      <li
                        className={
                          selectedItem === "CONTACT US"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("CONTACT US")}
                      >
                        <Link
                          className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          to="contact-us"
                        >
                          CONTACT US
                        </Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5>Services</h5>
                  </Accordion.Header>
                  <hr style={{ borderColor: "rgb(156 163 175)" }} />
                  <Accordion.Body>
                    <ul className="nav flex-column text-white cursor-pointer-updated">
                      <li
                        className={
                          selectedItem === "WEB DEV"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("WEB DEV")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            to="/service/web-development"
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          >
                            WEB DEV
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "BRANDING"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("BRANDING")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            to="/service/branding-logos-design-graphic-design-packages"
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          >
                            BRANDING
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "APP-DEV"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("APP-DEV")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            to="/service/applications-development"
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          >
                            APP-DEV
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "SEO"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("SEO")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            to="/service/search-engine-optimization"
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          >
                            SEO
                          </Link>
                        </div>
                      </li>

                      <li
                        className={
                          selectedItem === "PHOTOGRAPHY"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("PHOTOGRAPHY")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            to="/photography_videography"
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          >
                            PHOTO & VIDEOGRAPHY
                          </Link>
                        </div>
                      </li>

                      <li
                        className={
                          selectedItem === "ORM"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("ORM")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            to="/service/orm-online-reputation-management"
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          >
                            ORM
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "USER LOGIN"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("USER LOGIN")}
                      >
                        <Link
                          className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          to="https://app.pulsemarketing.io/"
                          target="_blank" // Add this line
                        >
                          USER LOGIN
                        </Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5>Resources</h5>
                  </Accordion.Header>
                  <hr style={{ borderColor: "rgb(156 163 175)" }} />
                  <Accordion.Body>
                    <ul className="nav flex-column text-white cursor-pointer-updated">
                      <li
                        className={
                          selectedItem === "CRM"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("CRM")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            to="crm"
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          >
                            CRM
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "AUDIT"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("AUDIT")}
                      >
                        <div className="d-none pb-2 text-lg-updated">
                          <Link
                            to="audit"
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                          >
                            AUDIT
                          </Link>
                        </div>
                      </li>
                      <li
                        className={
                          selectedItem === "OUR SERVICES"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("OUR SERVICES")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                            to="our-services"
                          >
                            OUR SERVICES
                          </Link>
                        </div>
                      </li>

                      <li
                        className={
                          selectedItem === "OUR SERVICES"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("OUR SERVICES")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                            to="/terms-and-conditions/"
                          >
                            TERMS &amp; CONDITIONS
                          </Link>
                        </div>
                      </li>

                      <li
                        className={
                          selectedItem === "OUR SERVICES"
                            ? "animated-border"
                            : "animated-border"
                        }
                        onClick={() => handleSelectItem("OUR SERVICES")}
                      >
                        <div className="pb-2 text-lg-updated">
                          <Link
                            className="pb-1 text-lg-updated text-white hover:gradient-text-updated"
                            to="/privacy"
                          >
                            PRIVACY POLICY
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="" style={{ background: "black" }}>
          <div className="d-flex flex-column flex-sm-row justify-content-between py-4 pulse-footer logo-content-wrapper text-white  mx-auto-updated">
            <div className="logo-content-wrapper-updated">
              <div className="gradient-text-updated">
                © 2024 Pulse Marketing Inc. All rights reserved.
              </div>
            </div>

            <div className="logo-content-wrapper-updated display-none-updated">
              <ul class="example-2">
                <li class="icon-content">
                  <a
                    href="https://www.facebook.com/pulsemarketing.io/"
                    target="_blank"
                    aria-label="facebook"
                    data-social="facebook"
                    className="svg-icon"
                  >
                    <div class="filled"></div>
                    <svg
                      className="svg-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_8_22725)">
                        <path
                          d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
                          fill="#686868"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8_22725">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <div class="tooltip">Facebook</div>
                </li>

                <li class="icon-content">
                  <a
                    href="https://www.linkedin.com/company/pulse-marketing-inc/"
                    target="_blank"
                    aria-label="LinkedIn"
                    data-social="linkedin"
                  >
                    <div class="filled"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-linkedin"
                      viewBox="0 0 16 16"
                      xmlSpace="preserve"
                    >
                      <path
                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </a>
                  <div class="tooltip">LinkedIn</div>
                </li>
                <li class="icon-content">
                  <a
                    href="https://www.google.com/search?rlz=1CDGOYI_enCA801CA923&hl=en-US&q=Pulse+Marketing+Inc.&ludocid=8690602816288484550&gsas=1&lsig=AB86z5VS1uhD8C0TEf_4MQU5HsGp&kgs=46e20b62b90303a0&shndl=-1&shem=lssle&source=sh/x/kp/local/3"
                    target="_blank"
                    aria-label="GitHub"
                    data-social="github"
                    className="svg-icon"
                  >
                    <div class="filled"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlSpace="preserve"
                    >
                      <g clip-path="url(#clip0_116_17)">
                        <path
                          d="M12.3999 10.782H23.7279C23.8439 11.382 23.9119 12.073 23.9149 12.779V12.782C23.9159 12.848 23.9169 12.926 23.9169 13.004C23.9169 15.135 23.3899 17.143 22.4599 18.905L22.4929 18.836C21.5519 20.598 20.1689 22.016 18.4889 22.973L18.4379 23C16.7629 23.945 14.7609 24.502 12.6289 24.502C12.5479 24.502 12.4669 24.501 12.3869 24.5H12.3989C12.3859 24.5 12.3699 24.5 12.3549 24.5C10.6829 24.5 9.09193 24.152 7.65093 23.525L7.72693 23.555C4.82493 22.336 2.56293 20.073 1.37293 17.249L1.34393 17.172C0.745926 15.793 0.398926 14.187 0.398926 12.5C0.398926 10.813 0.746926 9.20702 1.37393 7.75002L1.34393 7.82802C2.56293 4.92602 4.82593 2.66402 7.64993 1.47402L7.72693 1.44502C9.09093 0.848023 10.6799 0.501023 12.3509 0.501023H12.4019H12.3989C12.4579 0.500023 12.5279 0.499023 12.5979 0.499023C15.6429 0.499023 18.4089 1.69602 20.4509 3.64602L20.4469 3.64202L17.1809 6.78302C15.9929 5.63102 14.3709 4.92002 12.5829 4.92002C12.5179 4.92002 12.4539 4.92102 12.3889 4.92302H12.3979C12.3839 4.92302 12.3679 4.92302 12.3509 4.92302C10.9929 4.92302 9.72193 5.30102 8.63993 5.95702L8.67193 5.93902C6.42593 7.29702 4.94693 9.72702 4.94693 12.501C4.94693 15.275 6.42593 17.705 8.63793 19.044L8.67193 19.063C9.72293 19.701 10.9929 20.079 12.3509 20.079H12.4009H12.3979H12.4809C13.3449 20.079 14.1759 19.942 14.9549 19.687L14.8989 19.703C15.6149 19.481 16.2379 19.161 16.7919 18.753L16.7749 18.765C17.2609 18.392 17.6819 17.971 18.0429 17.501L18.0549 17.485C18.3669 17.092 18.6369 16.644 18.8449 16.164L18.8599 16.125C19.0089 15.775 19.1309 15.366 19.2059 14.941L19.2109 14.906H12.3999V10.782Z"
                          fill="#686868"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_116_17">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0.399902 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <div class="tooltip">Google</div>
                </li>
                <li class="icon-content">
                  <a
                    href="https://www.instagram.com/pulsemarketing.io/"
                    target="_blank"
                    aria-label="Instagram"
                    data-social="instagram"
                  >
                    <div class="filled"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-instagram"
                      viewBox="0 0 16 16"
                      xmlSpace="preserve"
                    >
                      <path
                        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </a>
                  <div class="tooltip">Instagram</div>
                </li>
                <li class="icon-content">
                  <a
                    href="https://www.youtube.com/@ianslater3586"
                    aria-label="Youtube"
                    data-social="youtube"
                  >
                    <div class="filled"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      target="_blank"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-youtube"
                      viewBox="0 0 16 16"
                      xmlSpace="preserve"
                    >
                      <path
                        d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </a>
                  <div class="tooltip">Youtube</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Footer;
