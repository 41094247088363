import React from "react";

const FaqBanner = () => {
  return (
    <div>
      <div className="faq-banner d-flex v-center h-center flex-column banner-responsive">
        <h1>FAQ’S</h1>
        <h5>be free to Ask Any Thing</h5>
      </div>
    </div>
  );
};

export default FaqBanner;
