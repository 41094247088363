import React from "react";
import bVideo from "../../assets/video/ourservices/Servicesbanner.mp4";

const SBanner = () => {
  return (
    <>
      <div className="servicesBanner v-center h-center">
        <div className="banner-content flex-column v-center h-center">
          <div className="content-back"></div>
          <h1 className="text-white text-center fw-bolder h1-updated">
            MULTIFACETED SOLUTIONS <br className="mobile-none" /> ONE
            DESTINATION
          </h1>
          <p className="text-white text-center paraupdated">
            Transforming visions into digital realities through 3D web
            development, SEO mastery, multimedia creativity, logo design, online
            reputation management and application innovation.
          </p>
        </div>
        <div className="banner-video w-100 h-100 ">
          <video loop autoPlay playsInline muted preload="auto">
            <source src={bVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
};

export default SBanner;
