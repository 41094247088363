import React from "react";
import bVideo from "../../assets/video/022.mp4";

const OrmBanner = () => {
  return (
    <div className="aboutBanner pl-hero-banner">
      <div className="orm-banner d-flex v-center h-center flex-column banner-responsive   ">
        <h1>ORM</h1>
        <h5>Building your online reputation</h5>
      </div>

      <div className="banner-video w-100 h-100 ">
        <video loop autoPlay playsInline muted preload="auto">
          <source src={bVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default OrmBanner;
