import React, { useEffect, useState } from "react";

const LazyLoadedImage = ({ src, alt, className, isLoad = false }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isLoad) {
      setLoaded(false);
      if (src) {
        const img = new Image();
        img.src = src;
        img.onload = () => setLoaded(true);
      }
    }
  }, [src]);

  return (
    <>
      {!loaded && isLoad ? (
        <div className="PaginationLoader">
          <div className="loader storycard"></div>
        </div>
      ) : (
        <img src={src} alt={alt} className={className} loading="lazy" />
      )}
    </>
  );
};

export default LazyLoadedImage;
