import React, { Suspense } from "react";

import { aboutImg } from "../content/data";
import LazyLoadedImage from "../shared/lazyLoadedImage";
import bVideo from "../../assets/video/about/aboutBanner.mp4";

const aboutbanner = () => {
  return (
    <>
      <div className="aboutBanner v-center h-center pl-hero-banner">
        <Suspense fallback="Loading...">
          <LazyLoadedImage
            src={aboutImg.Ellipse}
            alt="Ellipse"
            className="ellipse w-100 h-100 object-fit-cover"
          />
        </Suspense>
        <div className="banner-content flex-column v-center h-center">
          <div className="content-back"></div>
          <div className="text-white text-center h1-updated">
            EMPOWERING YOUR DIGITAL PRESENCE
          </div>
          <div className="text-white text-center mt-4 paraupdated">
            Transforming visions into digital realities through 3D web
            development, SEO mastery, multimedia creativity, logo design, online
            reputation management and application innovation.
          </div>
        </div>

        <div className="banner-video w-100 h-100 ">
          <video loop autoPlay playsInline muted preload="auto">
            <source src={bVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
};

export default aboutbanner;
