import React, { useState } from "react";
import { logocards } from "../content/data";
import Logocards from "../shared/logocards";

const Logos = () => {
  const [activeFilter, setActiveFilter] = useState("First");
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };
  return (
    <div className="max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
      <div className="logos d-flex v-center h-center flex-column">
        <h5 className="text-center heading ">
          Logos That Speak, Brands That Resonate
        </h5>
        <div className="S-wrapper my-5">
          <div className="two-heads d-flex v-center h-center flex-column">
            <h1>Logos</h1>
            <h2>Logos</h2>
          </div>
          {activeFilter === "First" ? (
            <div className="stories-wrapper max-w-7xl-updateds mx-auto-updateds awais">
              <Logocards cardImg={logocards.logocard1} />
              <Logocards cardImg={logocards.logocard2} />
              <Logocards cardImg={logocards.logocard3} />
              <Logocards cardImg={logocards.logocard4} />
              <Logocards cardImg={logocards.logocard5} />
              <Logocards cardImg={logocards.logocard6} />
              <Logocards cardImg={logocards.logocard7} />
              <Logocards cardImg={logocards.logocard8} />
              <Logocards cardImg={logocards.logocard9} />
              <Logocards cardImg={logocards.logocard10} />
              <Logocards cardImg={logocards.logocard29} />
              <Logocards cardImg={logocards.logocard30} />
              <Logocards cardImg={logocards.logocard31} />
              <Logocards cardImg={logocards.logocard32} />
              <Logocards cardImg={logocards.logocard33} />
              <Logocards cardImg={logocards.logocard34} />
              <Logocards cardImg={logocards.logocard35} />
              <Logocards cardImg={logocards.logocard36} />
              <Logocards cardImg={logocards.logocard37} />
              <Logocards cardImg={logocards.logocard38} />
              <Logocards cardImg={logocards.logocard39} />
              <Logocards cardImg={logocards.logocard40} />
              <Logocards cardImg={logocards.logocard41} />
              <Logocards cardImg={logocards.logocard42} />
              <Logocards cardImg={logocards.logocard43} />
              <Logocards cardImg={logocards.logocard44} />
              <Logocards cardImg={logocards.logocard45} />
              <Logocards cardImg={logocards.logocard46} />
              <Logocards cardImg={logocards.logocard47} />
              <Logocards cardImg={logocards.logocard48} />
              <Logocards cardImg={logocards.logocard49} />
              <Logocards cardImg={logocards.logocard50} />
              <Logocards cardImg={logocards.logocard51} />
              <Logocards cardImg={logocards.logocard52} />
              <Logocards cardImg={logocards.logocard53} />
              <Logocards cardImg={logocards.logocard54} />
              <Logocards cardImg={logocards.logocard55} />
              <Logocards cardImg={logocards.logocard56} />
              <Logocards cardImg={logocards.logocard57} />
              <Logocards cardImg={logocards.logocard58} />
              {/* <Logocards cardImg={logocards.logocard19} />
              <Logocards cardImg={logocards.logocard200} />
              <Logocards cardImg={logocards.logocard21} />
              <Logocards cardImg={logocards.logocard22} />
              <Logocards cardImg={logocards.logocard23} />
              <Logocards cardImg={logocards.logocard24} />
              <Logocards cardImg={logocards.logocard25} />
              <Logocards cardImg={logocards.logocard26} />
              <Logocards cardImg={logocards.logocard27} />
              <Logocards cardImg={logocards.logocard28} /> */}
            </div>
          ) : activeFilter === "Second" ? (
            <div className="stories-wrapper awais10">
              <Logocards cardImg={logocards.logocard11} />
              <Logocards cardImg={logocards.logocard12} />
              <Logocards cardImg={logocards.logocard13} />
              <Logocards cardImg={logocards.logocard14} />
              <Logocards cardImg={logocards.logocard15} />
              <Logocards cardImg={logocards.logocard16} />
              <Logocards cardImg={logocards.logocard17} />
              <Logocards cardImg={logocards.logocard18} />
              <Logocards cardImg={logocards.logocard19} />
              <Logocards cardImg={logocards.logocard20} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="pagination light-grey-shadow v-center h-center d-none">
          <p
            className={`body-large fw-bold next_icon pointer ${
              activeFilter === "First" ? "active" : ""
            }`}
            onClick={() => handleFilterClick("First")}
          >
            1
          </p>
          <p
            className={`body-large fw-bold next_icon pointer ${
              activeFilter === "Second" ? "active" : ""
            }`}
            onClick={() => handleFilterClick("Second")}
          >
            2
          </p>
          <p className="body-large fw-bold">3</p>
          <p className="body-large fw-bold">4</p>
          <p className="body-large fw-bold">5</p>
          <p className="body-large fw-bold">6</p>
          <p className="body-large fw-bold">7</p>
          <p className="body-large ">...</p>
          <p className="body-large ">16</p>
        </div>
      </div>
    </div>
  );
};

export default Logos;
