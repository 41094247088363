import React, { useEffect } from "react";
import SeoBanner from "../components/seo/seoBanner";
import SeoServices from "../components/seo/seoServices";
import Plan from "../components/seo/plan";
import Results from "../components/seo/results";
import Reasons from "../style/page/seo/reasons";
import Contactinfo from "../components/contact-us/contactinfo";
import SeoPricing from "../components/seo/seoPricing";
import ScrollToTop from "../components/shared/scrollToTop";
// import { Helmet } from "react-helmet-async";

const Seo = () => {
  ScrollToTop();
  // useEffect(() => {
  //   const script1 = document.createElement("script");
  //   script1.defer = true;
  //   script1.src =
  //     "https://online.seranking.com/frontend-dist/widget-manager/main.js";
  //   document.body.appendChild(script1);

  //   const script2 = document.createElement("script");
  //   script2.defer = true;
  //   script2.src =
  //     "https://online.seranking.com/frontend-dist/Widgets/js/main.js";
  //   script2.setAttribute("data-widget-type", "modal");
  //   script2.setAttribute("data-auto-open-time", "0");
  //   script2.setAttribute("data-widget-page-audit-id", "3093869-2917");
  //   document.body.appendChild(script2);

  //   // Clean up function
  //   return () => {
  //     document.body.removeChild(script1);
  //     document.body.removeChild(script2);
  //   };
  // }, []);
  return (
    <div>
      <SeoBanner />
      {/* <SeoServices /> */}
      <Plan />
      <Results />
      <Reasons />
      <SeoPricing />
      <Contactinfo showMarginTop={true} />

      {/* <Helmet>
        <script
          defer
          src="https://online.seranking.com/frontend-dist/widget-manager/main.js"
        ></script>
        <script
          defer
          src="https://online.seranking.com/frontend-dist/Widgets/js/main.js"
          data-widget-type="modal"
          data-auto-open-time="0"
          data-widget-page-audit-id="3093869-2896"
        ></script>
      </Helmet> */}
    </div>
  );
};

export default Seo;
