import React from "react";

const Portfolio = () => {
  return (
    <div>
      <div className="portfolio d-flex v-center h-center flex-column">
        <h1>OUR PORTFOLIO</h1>
      </div>
    </div>
  );
};

export default Portfolio;
