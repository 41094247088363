import React from "react";
import Calender from "../contact-us/calender";

const BookConsultatnt = () => {
  return (
    <div>
      <Calender />
    </div>
  );
};

export default BookConsultatnt;
