import React from "react";
import "./swiper.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  A11y,
  Autoplay,
  EffectCoverflow,
  EffectCreative,
  Navigation,
  Pagination,
  Parallax,
} from "swiper/modules";

import sliderData from "../../components/home/sliderData";

import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/parallax";
const FeatureWork = () => {
  const swiperParameters = {
    modules: [A11y, Autoplay, Navigation, Pagination, Parallax],
    navigation: {
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    },
    autoplay: { pauseOnMouseEnter: true, enabled: true, delay: 2000 },
    effect: "coverflow",
    slidesPerGroupAuto: false,
    navigation: true,
    loop: true,
    creativeEffect: {
      next: { shadow: true },
      prev: { shadow: true },
      limitProgress: 5,
    },
    parallax: { enabled: true },
    speed: 2100,
    coverflowEffect: { depth: 112 },
  };

  return (
    <>
      <div
        className="featuredWork"
        style={{ height: "100vh", background: "black", overflowX: "hidden" }}
      >
        {/* awais */}
        <Swiper {...swiperParameters}>
          {sliderData.map((slide) => (
            <SwiperSlide
              key={slide.id}
              className={slide.sliderClass}
              style={{
                backgroundImage: `url(${slide.backgroundImage})`,
                backgroundSize: "cover",
              }}
            >
              <div className={slide.divClass}>
                <div className="left">
                  <div className="later">
                    <h2 className="text-white">{slide.title}</h2>
                    <h1 className="text-white">
                      <span
                        className="text-greenish-blue number"
                        style={slide.style}
                      >
                        <span className="filled">{slide.number}</span>
                        <span className="stroke">{slide.number}</span>
                      </span>
                      <span className="hash">{slide.hash}</span> <br />
                      {slide.mainTitle.split(" ").map((word, index) => (
                        <React.Fragment key={index}>
                          {word} <br />
                        </React.Fragment>
                      ))}
                      <div className="layer rounded-circle"></div>
                    </h1>
                  </div>
                  <p className="text-white mt-4">{slide.description}</p>
                </div>
                <div className="right">
                  <img
                    src={slide.imgSrc}
                    alt={slide.imgAlt}
                    className="img-fluid-updated"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default FeatureWork;
