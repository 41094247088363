import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Thumbnailmodal from "../../assets/images/ModalThumbnail.png";
import CounterComponent from "../../components/counter/counter";

const Video = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="video mt-5 awais">
        <div className="top d-flex v-center h-center flex-column awais2 max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
          <div className="years d-flex v-center h-center flex-column">
            <h2 class="d-flex animated-text">
              <div>
                <CounterComponent className="head" targetValue={10} time={60} />
              </div>
              +
            </h2>
            <p className="body-large">Years of Experience</p>
          </div>
          <div className="client-video d-flex v-center h-center">
            {/* <img src={happy} alt="img" className="img-fluid mainImage" /> */}
            <video
              preload="auto"
              controls
              loop
              // autoPlay
              src="/assets/modal.mp4"
              className="mainImage"
              poster={Thumbnailmodal}
            ></video>

            <div className="animation pointer d-none" onClick={handleShow}>
              <img src="/assets/images/anim.png" alt="img" className=" inner" />
              <img src="/assets/images/PNG.png" alt="img" className="outer" />
              {/* <video
                loop
                autoPlay
                muted
                src="/assets/circleVideo.mp4"
                className="outer"
                onClick={handleShow}
              ></video> */}
            </div>
          </div>
        </div>
        <div
          style={{
            background: "black",
            position: "absolute",
            bottom: "-80px",
            width: "100%",
          }}
        >
          <div className="max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
            <div className="bottom d-flex">
              <div className="content d-flex  v-center h-center flex-column">
                <div>
                  <h2 className="text-greenish-blue"> 8K+</h2>
                </div>{" "}
                <h3>Happy Clients</h3>
              </div>
              <div className="projects d-flex align-items-end">
                <h6>25k+ Projects Completed Across North America</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for video */}

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="video-modal"
      >
        <Modal.Body className="modal-video">
          <video
            controls
            loop
            autoPlay
            muted
            preload="auto"
            src="/assets/modal.mp4"
          ></video>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Video;
