import React from "react";
import bVideo from "../../assets/video/011.mp4";

const Banner = () => {
  return (
    <div className="aboutBanner">
      <div className="banner d-flex v-center h-center flex-column banner-responsive">
        <h1>CONTENT CREATORS</h1>
        <h5>Powered by Pulse Marketing</h5>
      </div>

      <div className="banner-video w-100 h-100 ">
        <video loop autoPlay playsInline muted preload="auto">
          <source src={bVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Banner;
