import React from "react";
import bVideo from "../../assets/video/3.mp4";

const Appbanner = () => {
  return (
    <div className="aboutBanner v-center h-center">
      <div className="app-banner d-flex v-center h-center flex-column banner-responsive ">
        <h1>APP DEVELOPMENT</h1>
        <h5>Android / iOS / Hybrid </h5>
      </div>

      <div className="banner-video w-100 h-100 ">
        <video loop autoPlay playsInline muted preload="auto">
          <source src={bVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Appbanner;
