import React from "react";
import CounterComponent from "../../components/counter/counter";

const Resultcard = (props) => {
  return (
    <div>
      <div className="parent d-flex v-center h-center flex-column">
        <div className="resultCard h-center">
          {props?.isCounter === true && parseInt(props.heading) >= 0 ? (
            <CounterComponent
              isCallFrom="resultCard"
              targetValue={parseInt(props.heading)}
              letterPart={props.heading?.replace(parseInt(props.heading), "")}
              time={props?.time ? props?.time : 150}
            />
          ) : (
            <h4 className="text-white h-center">{props.heading}</h4>
          )}
          <div className="circle-anim"></div>
        </div>
        <p className="h-center">{props.content}</p>
      </div>
    </div>
  );
};

export default Resultcard;
