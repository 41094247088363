// import React from "react";
// import { Icon } from "@iconify/react";
// import CustomCheckBox from "../shared/customCheckBox";
// import { useState } from "react";
// import Input from "../shared/input";
// import { useEffect } from "react";
// // import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleReCaptcha } from "react-google-recaptcha-v3";

// const Contactinfo = ({ showLabel = true, showMarginTop = true }) => {
//   const [token, setToken] = useState("");

//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     companyName: "",
//     companyWebsite: "",
//     message: "",
//     seo: false,
//     gd: false,
//     wd: false,
//     orm: false,
//     ad: false,
//     cc: false,
//     ldbp: false,
//     pv: false,
//     file: null,
//     captcha: "",
//   });

//   const handleInputChange = (e) => {
//     const { name, value, type, checked, files } = e.target;

//     if (type === "checkbox") {
//       // Update checkbox values to label text if checked, otherwise an empty string
//       setFormData({
//         ...formData,
//         [name]: checked ? value : "",
//       });
//     } else if (type === "file") {
//       // Handle file upload
//       setFormData({
//         ...formData,
//         [name]: files[0],
//       });
//     } else {
//       // Handle other input types
//       setFormData({
//         ...formData,
//         [name]: value,
//       });
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(formData);
//   };

//   console.log("token", token);

//   return (
//     <div>
//       <div className="Contactinfo d-flex v-center h-center flex-column">
//         {showLabel && (
//           <h2 className={`mb-5 ${showMarginTop ? "mt-5" : ""}`}>
//             BOOK CONSULTATION
//           </h2>
//         )}
//         <div className="content d-flex">
//           <div className="left d-flex flex-column v-center h-center">
//             <h1>
//               Contact <br /> <span>Information</span>
//             </h1>
//             <label className="small mt-4">
//               Say something to start a live chat!
//             </label>
//             <div className="green-shadow"></div>
//           </div>
//           <div className="right d-flex flex-column">
//             <div className="input-box-wrapper gap-5">
//               <Input
//                 label="First Name"
//                 labelClass="mb-2"
//                 inputType="text"
//                 wrapperClasses="w-100"
//                 onChange={handleInputChange}
//                 name="firstName"
//                 value={formData.firstName}
//               />
//               <Input
//                 label="Last Name"
//                 labelClass="mb-2"
//                 inputType="text"
//                 wrapperClasses="w-100"
//                 onChange={handleInputChange}
//                 name="lastName"
//                 value={formData.lastName}
//               />

//               <Input
//                 label="Email"
//                 labelClass="mb-2"
//                 inputType="mail"
//                 wrapperClasses="w-100"
//                 onChange={handleInputChange}
//                 name="email"
//                 value={formData.email}
//               />

//               <Input
//                 label="Phone Number"
//                 labelClass="mb-2"
//                 inputType="number"
//                 wrapperClasses="w-100"
//                 onChange={handleInputChange}
//                 name="phone"
//                 value={formData.phone}
//               />

//               <Input
//                 label="Company Name"
//                 labelClass="mb-2"
//                 inputType="text"
//                 wrapperClasses="w-100"
//                 onChange={handleInputChange}
//                 name="companyName"
//                 value={formData.companyName}
//               />

//               <Input
//                 label="Company Website"
//                 labelClass="mb-2"
//                 inputType="text"
//                 wrapperClasses="w-100"
//                 onChange={handleInputChange}
//                 name="companyWebsite"
//                 value={formData.companyWebsite}
//               />
//             </div>

//             <div className="service d-flex flex-column">
//               <p className="medium">What services are you interested in?</p>
//               <div className="labels">
//                 <CustomCheckBox
//                   label="Search Engine Optimization"
//                   id="seo"
//                   name="seo"
//                   value="Search Engine Optimization"
//                   onChange={handleInputChange}
//                   checked={formData.seo}
//                 />
//                 {/* <CustomCheckBox
//                   label="Graphic Design"
//                   id="gd"
//                   name="gd"
//                   value="Graphic Design"
//                   onChange={handleInputChange}
//                   checked={formData.gd}
//                 /> */}
//                 <CustomCheckBox
//                   label="Web Development"
//                   id="wd"
//                   name="wd"
//                   value="Web Development"
//                   onChange={handleInputChange}
//                   checked={formData.wd}
//                 />
//                 <CustomCheckBox
//                   label="ORM (Online Reputation Management)"
//                   id="orm"
//                   name="orm"
//                   value="ORM (Online Reputation Management)"
//                   onChange={handleInputChange}
//                   checked={formData.orm}
//                 />
//                 <CustomCheckBox
//                   label="Applications Development"
//                   id="ad"
//                   name="ad"
//                   value="Applications Development"
//                   onChange={handleInputChange}
//                   checked={formData.ad}
//                 />
//                 <CustomCheckBox
//                   label="Logo Design & Branding Packages"
//                   id="ldbp"
//                   name="ldbp"
//                   value="Logo Design & Branding Packages"
//                   onChange={handleInputChange}
//                   checked={formData.ldbp}
//                 />

//                 <CustomCheckBox
//                   label="Photography Videography"
//                   id="pv"
//                   name="pv"
//                   value="Photography Videography"
//                   onChange={handleInputChange}
//                   checked={formData.pv}
//                 />
//                 {/* <CustomCheckBox
//                   label="Content Creation"
//                   id="cc"
//                   name="cc"
//                   value="Content Creation"
//                   onChange={handleInputChange}
//                   checked={formData.cc}aaa
//                 /> */}
//               </div>
//             </div>

//             <div className="message">
//               <p className="medium">Message</p>
//               <textarea
//                 id=""
//                 placeholder="Write your message.."
//                 name="message"
//                 value={formData.message}
//                 onChange={handleInputChange}
//               />
//             </div>
//             <div>
//               {/* <ReCAPTCHA
//                   sitekey={key}
//                   onChange={(e) => console.log("server recaptha", e)}
//                   name="captcha"
//                 /> */}

//               <GoogleReCaptcha
//                 action="contact_us"
//                 onVerify={(e) => {
//                   if (!token) setToken(e);
//                 }}
//               />
//             </div>

//             <div className="two-buttons d-flex justify-content-between">
//               <label id="1" className="d-flex v-center h-center">
//                 Upload File
//                 <input
//                   type="file"
//                   name="file"
//                   id="1"
//                   className="d-none"
//                   onChange={handleInputChange}
//                 />
//                 <Icon
//                   icon="material-symbols:upload"
//                   width="24"
//                   height="24"
//                   className="ms-2"
//                 />
//               </label>
//               <button className="rightbutton" onClick={handleSubmit}>
//                 Send Message
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Contactinfo;

import React from "react";
import { Icon } from "@iconify/react";
import CustomCheckBox from "../shared/customCheckBox";
import { useState } from "react";
import Input from "../shared/input";
import { useEffect } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const Contactinfo = ({ showLabel = true, showMarginTop = true }) => {
  const [token, setToken] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    companyWebsite: "",
    message: "",
    seo: false,
    gd: false,
    wd: false,
    orm: false,
    ad: false,
    cc: false,
    ldbp: false,
    pv: false,
    file: null,
    captcha: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === "checkbox") {
      // Update checkbox values to label text if checked, otherwise an empty string
      setFormData({
        ...formData,
        [name]: checked ? value : "",
      });
    } else if (type === "file") {
      // Handle file upload
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      // Handle other input types
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  console.log("token", token);

  return (
    <div className="">
      <div className="backgroundupdatedpadding">
        <div className="Contactinfo d-flex v-center h-center flex-column">
          {showLabel && (
            <h2 className={`mb-5  ${showMarginTop ? "mt-5 text-black" : ""}`}>
              Book Consultation
            </h2>
          )}
          <div className="content d-flex">
            <div className="left d-flex flex-column v-center h-center">
              <div
                className="d-flex flex-column"
                style={{ gap: "50px", zIndex: "10" }}
              >
                <div>
                  <h1 className="text-white">
                    Contact <br /> <span>Information</span>
                  </h1>
                  <label className="small mt-4 text-white">
                    Say something to start a live chat!
                  </label>
                </div>
                <div>
                  <div
                    class="three-button d-flex flex-column"
                    style={{ position: "relative", zIndex: "99", gap: "30px" }}
                  >
                    <button className="Latestbuttoncss">
                      <a href="mailto:hi@pulsemarketing.ca">
                        hi@pulsemarketing.ca
                      </a>
                    </button>
                    <button className="Latestbuttoncss">
                      <a href="mailto:sales@pulsemarketing.ca">
                        sales@pulsemarketing.ca
                      </a>
                    </button>
                    <button className="Latestbuttoncss">
                      <a href="mailto:ai@pulsemarketing.ca">
                        ai@pulsemarketing.ca
                      </a>
                    </button>
                  </div>
                </div>
                <div>
                  <div className=" text-white Burrard-Street text-center">
                    {/* <span>
                      <Icon
                        icon="fluent:location-12-filled"
                        color="#50a2d4"
                        width="20"
                        height="20"
                      />
                    </span>
                    Address */}
                    <br />
                    595 Burrard Street, Vancouver, B.C. <br /> V7X 1A3, Canada
                  </div>
                </div>
              </div>
              <div className="green-shadow"></div>
            </div>

            <div className="right d-flex flex-column">
              <div className=" gap-5">
                <div
                  className="IFrameMainContainer"
                  style={{ width: "100%", background: "white" }}
                >
                  <iframe
                    id="JotFormIFrame-240545207923050"
                    title="Pulse Marketing Form"
                    allowtransparency="true"
                    allowFullScreen={true}
                    allow="geolocation; microphone; camera"
                    src="https://form.jotform.com/240545207923050"
                    frameBorder="0"
                    style={{
                      minWidth: "100%",
                      maxWidth: "100%",

                      boxShadow: "none",
                      background: "white",
                      border: "none",
                    }}
                    scrolling="no"
                    className="iframewidth"
                  ></iframe>
                </div>
              </div>

              {/* <div className="service d-flex flex-column">
              <p className="medium">What services are you interested in?</p>
              <div className="labels">
                <CustomCheckBox
                  label="Search Engine Optimization"
                  id="seo"
                  name="seo"
                  value="Search Engine Optimization"
                  onChange={handleInputChange}
                  checked={formData.seo}
                />

                <CustomCheckBox
                  label="Web Development"
                  id="wd"
                  name="wd"
                  value="Web Development"
                  onChange={handleInputChange}
                  checked={formData.wd}
                />
                <CustomCheckBox
                  label="ORM (Online Reputation Management)"
                  id="orm"
                  name="orm"
                  value="ORM (Online Reputation Management)"
                  onChange={handleInputChange}
                  checked={formData.orm}
                />
                <CustomCheckBox
                  label="Applications Development"
                  id="ad"
                  name="ad"
                  value="Applications Development"
                  onChange={handleInputChange}
                  checked={formData.ad}
                />
                <CustomCheckBox
                  label="Logo Design & Branding Packages"
                  id="ldbp"
                  name="ldbp"
                  value="Logo Design & Branding Packages"
                  onChange={handleInputChange}
                  checked={formData.ldbp}
                />

                <CustomCheckBox
                  label="Photography Videography"
                  id="pv"
                  name="pv"
                  value="Photography Videography"
                  onChange={handleInputChange}
                  checked={formData.pv}
                />
              </div>
            </div> */}
              {/*
            <div className="message">
              <p className="medium">Message</p>
              <textarea
                id=""
                placeholder="Write your message.."
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              />
            </div> */}
              {/* <div>
              <GoogleReCaptcha
                action="contact_us"
                onVerify={(e) => {
                  if (!token) setToken(e);
                }}
              />
            </div> */}
              {/*
            <div className="two-buttons d-flex justify-content-between">
              <label id="1" className="d-flex v-center h-center">
                Upload File
                <input
                  type="file"
                  name="file"
                  id="1"
                  className="d-none"
                  onChange={handleInputChange}
                />
                <Icon
                  icon="material-symbols:upload"
                  width="24"
                  height="24"
                  className="ms-2"
                />
              </label>
              <button className="rightbutton" onClick={handleSubmit}>
                Send Message
              </button>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactinfo;
