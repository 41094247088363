import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import ScrollToTop from "../components/shared/scrollToTop";

const AuditModal = () => {
  const handleClose = () => {
    document.getElementById("myIframe").style.display = "none";
    document.getElementById("modal-target").style.display = "none";
  };
  ScrollToTop();
  return (
    <>
      <div id="modal-target" style={{ position: "relative" }}>
        <iframe
          id="myIframe"
          src="http://localhost:3000/modalaudit"
          style={{ height: "583px", width: "360px", borderRadius: "15px" }}
        ></iframe>

        <div
          style={{ position: "absolute", top: "13px", right: "0px" }}
          onClick={handleClose}
        >
          <div
            role="button"
            style={{ backgroundColor: "white", padding: "5px" }}
          >
            <span id="myIframe">Close</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditModal;
