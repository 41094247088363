import React from "react";
import { portfolio } from "../content/data";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const Teamspirit = () => {
  const navigate = useNavigate();

  const navigateToAboutUsSection = (e) => {
    e.preventDefault();
    navigate("/about-us");
    setTimeout(() => {
      window.scrollTo({
        top: document.querySelector(".team-section").offsetTop,
        behavior: "smooth",
      });
    }, 0);
  };
  return (
    <>
      <div style={{ background: "black" }}>
        <div className="max-w-7xl-updateds-app mx-auto-updateds-app-about-page">
          <div className="teamspirit-Section d-flex">
            <div className="left-div">
              <p className="text-white paraupdated pb-3">
                Where Talent Meets Dedication: Our Team, Our Pride
              </p>
              <h1 className="fw-bolder text-greenish-blue pb-3">
                Triumphant <br /> Team Spirit
              </h1>
              <img
                src={portfolio.teamSpirit}
                alt="teamSpiritImg"
                className="img-fluid"
              />
            </div>

            <div className="right-div">
              <p className=" text-white">
                We speak your language and have the same aversion to verbiage.
                What’s more, we think our humour might tickle you.
                <br />
                <br />
                In short: we’re just a team of experts who love what they do,
                and we love sharing it even more. Come to us with your problems,
                and we’ll turn them into solutions.
              </p>
              <a href="/about-us" onClick={navigateToAboutUsSection}>
                <button className="text-black fw-semibold h-center v-center gap-2">
                  Meet The Team
                  <Icon
                    icon="ph:arrow-right-bold"
                    color="black"
                    width="28"
                    height="24"
                    className="rightArrow"
                  />
                  <span className="circle-anim"></span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teamspirit;
